interface Props {
    size?: number;
    color?: string;
}

const DownloadIcon = ({ size, color }: Props) => {
    return (
        <svg
            width={size ?? 24}
            height={size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.59 10.09L13 12.67V3H11V12.67L8.41 10.09L7 11.5L12 16.5L17 11.5L15.59 10.09ZM19 19V12H21V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V12H5V19H19Z"
                    fill={color ?? '#414552'}
                />
            </g>
        </svg>
    );
};

export default DownloadIcon;
