interface Props {
    size?: number;
}

const MapCenterIcon = ({ size }: Props) => {
    return (
        <svg
            width={size ?? 60}
            height={size ?? 60}
            viewBox="0 0 60 60"
            fill="none"
        >
            <circle
                cx="30"
                cy="30"
                r="29.5"
                fill="#74FFA0"
                fillOpacity="0.35"
                stroke="#74FFA0"
            />
            <circle
                cx="30"
                cy="30"
                r="11"
                fill="#74FFA0"
                stroke="white"
                strokeWidth="4"
            />
            <path
                d="M27 26V28.5472H30.9309C31.9604 28.5472 31.9916 29.8377 30.9309 29.8377H28.0295C27.2059 29.9736 27 30.6868 27 31.0264V35C28.8719 34.9185 29.3398 33.2226 29.3398 32.3849H31.3677C34.893 32.0113 34.8618 26.3057 31.3677 26H27Z"
                fill="white"
            />
        </svg>
    );
};

export default MapCenterIcon;
