import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { User } from '../types/user.type';
import SubscriptionStatus from '../constants/subscription.status';

export const mapUserHasSubscription = (
    user: User
): {
    value: string;
    txtColor: string;
    bgColor: string;
    expiration?: string;
} => {
    const noSubscription = {
        value: 'No posee',
        txtColor: '#707270',
        bgColor: '#ececec',
    };
    const inactiveSubscription = {
        value: 'Vencida',
        txtColor: '#af8d07',
        bgColor: '#f7f1d5',
    };

    const hasSubscription = {
        value: 'Activa',
        txtColor: '#07AF25',
        bgColor: '#D5F7DB',
    };

    if (!user.subscriptions || user.subscriptions.length === 0)
        return noSubscription;

    const activeSubscription = user.subscriptions.find(
        (subscription) => subscription.status === SubscriptionStatus.ACTIVE
    );

    if (!activeSubscription) return inactiveSubscription;

    return {
        ...hasSubscription,
        expiration: format(
            new Date(activeSubscription.expiresAt),
            "dd 'de' MMMM 'de' yyyy",
            { locale: es }
        ),
    };
};
