import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ReactElement } from 'react';

import styles from './table-row.module.scss';

interface Props {
    id?: any;
    data: ColumnType[];
    onClick?: (id?: any) => void;
}

type StatusType = {
    bgColor: string;
    txtColor: string;
    value: string;
};

type ColumnType = {
    type: 'text' | 'number' | 'status' | 'date' | 'component';
    value: string | number | Date | StatusType | ReactElement;
};

const TableRow = (props: Props) => {
    const buildRow = (data: ColumnType[]): ReactElement[] => {
        return data.map((column, idx) => {
            let innerElement;
            switch (column.type) {
                case 'date':
                    innerElement = (
                        <td key={idx}>
                            {format(
                                new Date(column.value.toString()),
                                'MMMM dd, yyyy hh:mm:ss aaaa',
                                { locale: es }
                            )}
                        </td>
                    );
                    break;
                case 'status':
                    // eslint-disable-next-line no-case-declarations
                    const status = column.value as StatusType;
                    innerElement = (
                        <span
                            className={styles.status}
                            style={{
                                background: status.bgColor,
                                color: status.txtColor,
                            }}
                        >
                            {status.value}
                        </span>
                    );
                    break;
                case 'number':
                    innerElement = Number(column.value);
                    break;
                case 'component':
                    innerElement = column.value as ReactElement;
                    break;
                case 'text':
                default:
                    innerElement = column.value.toString();
                    break;
            }

            return <td key={idx}>{innerElement}</td>;
        });
    };

    return (
        <tr className={styles.row} onClick={() => props.onClick?.(props.id)}>
            {buildRow(props.data)}
        </tr>
    );
};

export default TableRow;
