import classNames from 'classnames';
import { MouseEvent, useEffect, useState } from 'react';

import { useNavigation } from '../../hooks/navigation.hook';
import { useAuthStore, useOperatorStore, useSettingsStore } from '../../store';
import Table from '../../components/table/table.component';
import { Operator } from '../../types/operator.type';
import TrashIcon from '../../icons/trash.icon';
import Modal from '../../components/modal/modal';
import Button from '../../components/button/button';

import styles from './operators.module.scss';

const OperatorsView = () => {
    const { navigate } = useNavigation();
    const { accessToken } = useAuthStore();
    const { theme } = useSettingsStore();
    const { employees, fetchAllEmployees, deleteOperator } = useOperatorStore();

    const [rows, setRows] = useState<any[]>([]);
    const [deletionModal, setDeletionModal] = useState({
        shown: false,
        confirmButton: {
            text: 'Sí, eliminar operador',
            disabled: false,
        },
    });
    const [deletedOperator, setDeletedOperator] = useState<
        Operator | undefined
    >();

    useEffect(() => {
        accessToken && fetchAllEmployees(accessToken);
    }, [accessToken]);

    useEffect(() => {
        if (employees.length === 0) return;
        mapOperators(employees);
    }, [employees]);

    const onDelete = (ev: MouseEvent, id: number) => {
        ev.stopPropagation();
        setDeletedOperator(employees.find((employee) => employee.id === id));
        toggleDeletionModal();
    };

    const mapOperators = (operators: Operator[]) => {
        const rows = operators.map((operator) => ({
            id: operator.id,
            data: [
                { type: 'number', value: operator.id },
                {
                    type: 'string',
                    value: `${operator.firstName} ${operator.lastName}`,
                },
                {
                    type: 'string',
                    value: operator.code ?? '-',
                },
                {
                    type: 'string',
                    value: operator.email,
                },
                {
                    type: 'string',
                    value: operator.role?.displayName ?? '-',
                },
                {
                    type: 'component',
                    value: (
                        <button
                            className={styles.deleteButton}
                            onClick={(ev) => onDelete(ev, operator.id)}
                        >
                            <TrashIcon size={20} color={'#5c636b'} />
                        </button>
                    ),
                },
            ],
        }));
        setRows(rows);
    };

    const toggleDeletionModal = () => {
        setDeletionModal((prev) => ({
            ...prev,
            shown: !prev.shown,
        }));
    };

    const confirmDeletion = async () => {
        setDeletionModal((prev) => ({
            ...prev,
            confirmButton: { text: 'Cargando...', disabled: true },
        }));
        await deleteOperator(deletedOperator!.id, accessToken!);
        setDeletionModal((prev) => ({
            ...prev,
            shown: false,
            confirmButton: { text: 'Sí, eliminar operador', disabled: false },
        }));
    };

    const goToCreation = () => navigate('/create-operator');

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Lista de operadores</h1>

                <div className={'flex align-center'} style={{ gap: 8 }}>
                    <Button onPress={goToCreation}>Crear nuevo operador</Button>
                </div>

                <Table
                    header={['ID', 'Nombre', 'Código', 'Correo', 'Rol']}
                    data={rows}
                    currentPage={1}
                    totalPages={1}
                    onPageChange={() => null}
                    onItemSelected={(id) => navigate(`/operators/${id}`)}
                />
            </div>
            <Modal shown={deletionModal.shown} onDiscard={toggleDeletionModal}>
                <div className={styles.modal}>
                    <h4>Eliminar operador</h4>
                    <span>
                        ¿Estás seguro que quieres eliminar al operador{' '}
                        {deletedOperator?.firstName} {deletedOperator?.lastName}
                        ?
                    </span>
                    <div className={styles.actions}>
                        <button
                            className={styles.discard}
                            onClick={toggleDeletionModal}
                        >
                            Cerrar este modal
                        </button>
                        <button
                            className={styles.confirm}
                            disabled={deletionModal.confirmButton.disabled}
                            onClick={confirmDeletion}
                        >
                            {deletionModal.confirmButton.text}
                        </button>
                    </div>
                </div>
            </Modal>
        </main>
    );
};

export default OperatorsView;
