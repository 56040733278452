import { useLocation, useNavigate } from 'react-router-dom';

export type NavigationFunction = (
    path: string,
    otps?: { replace?: boolean; targetBlank?: boolean }
) => void;

/**
 * This a list of no turn back paths and go-back function should redirect to home ('/')
 */
const RABBIT_HOLE_PATHS = ['/create-petition'];

export const useNavigation = () => {
    const { pathname: currentPath } = useLocation();
    const navigate = useNavigate();

    const goTo = (
        path: string,
        opts?: { replace?: boolean; targetBlank?: boolean }
    ) => {
        if (path === currentPath) return;
        if (opts?.targetBlank) {
            window.open(path, '_blank');
        } else {
            return navigate(path, { replace: opts?.replace });
        }
    };

    const goBack = () => {
        if (RABBIT_HOLE_PATHS.includes(currentPath)) {
            return navigate('/', { replace: true });
        }
        return navigate(-1);
    };

    return {
        navigate: goTo,
        currentPath,
        goBack,
    };
};
