interface Props {
    color?: string;
    size?: number;
}

const SendIcon = (props: Props) => {
    return (
        <svg
            viewBox="0 0 24 24"
            width={props.size ?? 24}
            height={props.size ?? 24}
            preserveAspectRatio="xMidYMid meet"
            className=""
            version="1.1"
            x="0px"
            y="0px"
            enableBackground="new 0 0 24 24"
        >
            <path
                fill={props.color ?? '#fff'}
                d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"
            ></path>
        </svg>
    );
};

export default SendIcon;
