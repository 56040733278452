import { ReactNode } from 'react';
import classNames from 'classnames';

import { ThemeType } from '../../types/theme.type';

import styles from './settings.module.scss';
import LightTheme from './light-theme';
import DarkTheme from './dark-theme';

interface ThemeSelectionProps {
    onSelect: (theme: ThemeType) => void;
    defaultValue: ThemeType;
}

const ThemeSelection = ({ onSelect, defaultValue }: ThemeSelectionProps) => {
    return (
        <div className={styles.themes}>
            <Theme
                image={<LightTheme />}
                text={'Claro'}
                value={'light'}
                onSelect={onSelect}
                selected={defaultValue === 'light'}
            />
            <Theme
                image={<DarkTheme />}
                text={'Oscuro'}
                value={'dark'}
                onSelect={onSelect}
                selected={defaultValue === 'dark'}
            />
        </div>
    );
};

interface ThemeProps {
    image: ReactNode;
    text: string;
    value: ThemeType;
    onSelect: (theme: ThemeType) => void;
    selected: boolean;
}

const Theme = ({ image, text, value, selected, onSelect }: ThemeProps) => (
    <div className={styles.col}>
        <div
            className={classNames({
                [styles.imageContainer]: true,
                [styles.selected]: selected,
            })}
        >
            {image}
        </div>
        <div className={styles.select}>
            <input
                type={'radio'}
                name={'theme'}
                value={value}
                checked={selected}
                onChange={(ev) => onSelect(ev.target.value as ThemeType)}
            />
            <label>{text}</label>
        </div>
    </div>
);

export default ThemeSelection;
