interface Props {
    color?: string;
    size?: number;
}

const DropdownIcon = (props: Props) => {
    return (
        <svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    d="M16.59 8.29492L12 12.8749L7.41 8.29492L6 9.70492L12 15.7049L18 9.70492L16.59 8.29492Z"
                    fill={props.color ?? 'black'}
                />
            </g>
        </svg>
    );
};

export default DropdownIcon;
