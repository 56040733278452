import { useEffect, useState, MouseEvent } from 'react';
import { object, string } from 'yup';

import { Credentials } from '../../types/credentials.type';

import styles from './login.module.scss';

interface Props {
    onSubmit: (creds: Credentials) => void;
    failureMessage?: string;
}

const LoginForm = ({ onSubmit, failureMessage }: Props) => {
    const [credentials, setCredentials] = useState<Credentials>({
        email: '',
        password: '',
    });
    const [buttonConfig, setButtonConfig] = useState({
        text: 'Continuar',
        disabled: true,
    });

    useEffect(() => {
        checkCredentials(credentials);
    }, [credentials]);

    useEffect(() => {
        failureMessage &&
            setButtonConfig({
                text: 'continuar',
                disabled: true,
            });
    }, [failureMessage]);

    const onChange = (key: string, value: string) => {
        setCredentials((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const checkCredentials = async (credentials: Credentials) => {
        const schema = object({
            email: string().email().required(),
            password: string().min(8).required(),
        });
        const valid = await schema.isValid(credentials);
        setButtonConfig({
            text: 'continuar',
            disabled: !valid,
        });
    };

    const submitCredentials = (ev: MouseEvent) => {
        ev.preventDefault();
        setButtonConfig({
            text: 'cargando...',
            disabled: true,
        });
        onSubmit(credentials);
    };

    return (
        <form>
            <h1>iniciar sesión</h1>

            <div className={styles.image} />

            <div className={styles.inputs}>
                <input
                    type={'email'}
                    placeholder={'Correo electrónico'}
                    onChange={(ev) => onChange('email', ev.target.value)}
                />
                <input
                    type={'password'}
                    placeholder={'Contraseña'}
                    onChange={(ev) => onChange('password', ev.target.value)}
                />
                <span className={styles.error}>{failureMessage}</span>
                <button
                    disabled={buttonConfig.disabled}
                    onClick={submitCredentials}
                >
                    {buttonConfig.text}
                </button>
            </div>
        </form>
    );
};

export default LoginForm;
