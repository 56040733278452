interface Props {
    color?: string;
    size?: number;
}

const CollapseIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 44}
            height={props.size || 44}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke={props.color || '#565454'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M10 12l10 0" />
            <path d="M10 12l4 4" />
            <path d="M10 12l4 -4" />
            <path d="M4 4l0 16" />
        </svg>
    );
};

export default CollapseIcon;
