import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import {
    Chart,
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
} from 'chart.js';

import App from './app';
import './index.scss';

// Registering Chart.JS components before initialization
Chart.register(
    ArcElement,
    Tooltip,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement
);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
