import { useState } from 'react';

import CloseIcon from '../../../icons/close.icon';
import CircleCheckIcon from '../../../icons/circle-check.icon';
import Note from '../../../components/note/note';
import SearchUserInput from '../../../components/search-user-input/search-user-input.component';
import { User } from '../../../types/user.type';
import SubscriptionStatus from '../../../constants/subscription.status';

import styles from './user-selection.module.scss';
import CreateUserModal from './create-user-modal';

interface Props {
    selectedUser?: User;
    userList: User[];
    onClear: () => void;
    onSelect: (id: number) => void;
    onSearch: (text: string) => void;
    onCreated: (user: User) => void;
}

const UserSelection = ({
    selectedUser,
    onClear,
    onSelect,
    userList,
    onSearch,
    onCreated,
}: Props) => {
    const [modalShown, setModalShown] = useState(false);

    const hasActiveSubscription = (user: User): boolean => {
        if (!user.subscriptions) return false;

        return user.subscriptions.some(
            (sub) => sub.status === SubscriptionStatus.ACTIVE
        );
    };

    const toggleModal = () => setModalShown(!modalShown);

    return (
        <section>
            <label>Selecciona al usuario:</label>
            <div className={styles.formSection}>
                {selectedUser ? (
                    <>
                        <div className={styles.userDetails}>
                            <div className={styles.spaceBetween}>
                                <b style={{ fontSize: 14 }}>
                                    {selectedUser.firstName}{' '}
                                    {selectedUser.lastName}
                                </b>
                                <CloseIcon
                                    size={16}
                                    stroke={2}
                                    className={styles.closeIcon}
                                    onClick={onClear}
                                />
                            </div>
                            <span>
                                {selectedUser.documentType}-
                                {selectedUser.document}
                            </span>
                            <span>{selectedUser.phoneNumber}</span>
                        </div>
                        {hasActiveSubscription(selectedUser) ? (
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 8,
                                }}
                            >
                                <CircleCheckIcon color={'#5ccdad'} size={18} />
                                suscripción activa
                            </span>
                        ) : (
                            <Note>
                                ⚠️ El usuario no posee una suscripción activa.
                                Esta será una solicitud "On-Demand"
                            </Note>
                        )}
                    </>
                ) : (
                    <>
                        <SearchUserInput
                            results={userList}
                            onSearch={onSearch}
                            onSelect={(user) => onSelect(user.id)}
                            placeholder={'Busca por nombre o cédula aquí'}
                        />
                        <div className={'flex align-center'} style={{ gap: 4 }}>
                            <span>¿No existe?</span>
                            <u
                                className={styles.createUserLink}
                                onClick={toggleModal}
                            >
                                O créalo aquí
                            </u>
                            {/*<Tooltip*/}
                            {/*    info={*/}
                            {/*        'Esta opción está inhabilitada temporalmente.'*/}
                            {/*    }*/}
                            {/*/>*/}
                        </div>
                    </>
                )}
            </div>
            <CreateUserModal
                shown={modalShown}
                onDiscard={toggleModal}
                onUserCreation={onCreated}
            />
        </section>
    );
};

export default UserSelection;
