import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { compareAsc, format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';

import { DateRange } from '../../types/date-range.type';

import GoBackIcon from './go-back.icon';
import GoNextIcon from './go-next.icon';
import IDate from './date.type';
import styles from './modal.module.scss';

const DAYS_OF_THE_WEEK = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

interface Props {
    days: IDate[];
    position: { x: number; y: number };
    visibility: boolean;
    onDateSelect: (date: IDate) => void;
    prevMonth: () => void;
    nextMonth: () => void;
    isRange?: boolean;
    onRange?: (date: DateRange) => void;
}

export const DatePickerModal = React.forwardRef((props: Props, ref: any) => {
    const [dateRange, setDateRange] = useState<DateRange>({});

    useEffect(() => {
        props.isRange &&
            dateRange.from &&
            dateRange.to &&
            props.onRange?.(dateRange);
    }, [props.isRange, dateRange]);

    const showCurrentMonthAndYear = (): string => {
        if (props.days.length === 0) return '';

        return formatDate(
            new Date(
                props.days[0].year,
                props.days[0].month,
                props.days[0].date
            ),
            'MMMM yyyy',
            { locale: es }
        );
    };

    const selectDate = (date: IDate) => {
        if (!props.isRange) {
            return props.onDateSelect(date);
        }

        const newDate = new Date(date.year, date.month, date.date);
        newDate.setHours(0, 0, 0, 0);
        if (!dateRange.from || dateRange.to) {
            setDateRange({ from: newDate, to: undefined });
        } else {
            setDateRange((prev) => ({ ...prev, to: newDate }));
        }
    };

    const checkIfIsSelected = (d: IDate): boolean => {
        let date: Date | string = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);
        date = date.toDateString();
        return (
            dateRange.from?.toDateString() === date ||
            dateRange.to?.toDateString() === date
        );
    };

    const checkIfIsFrom = (d: IDate): boolean => {
        if (!dateRange.to) return false;

        const date = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);

        return dateRange.from?.toDateString() === date.toDateString();
    };

    const checkIfIsTo = (d: IDate): boolean => {
        const date = new Date(d.year, d.month, d.date);
        date.setHours(0, 0, 0, 0);

        return dateRange.to?.toDateString() === date.toDateString();
    };

    const checkIfIsInterval = (d: IDate): boolean => {
        const date = new Date(d.year, d.month, d.date);

        if (!dateRange.from || !dateRange.to) return false;

        return (
            compareAsc(date, dateRange.from) === 1 &&
            compareAsc(date, dateRange.to) === -1
        );
    };

    const buildCalendarRow = (days: IDate[]) => {
        if (days.length === 0) return;

        let itemPerRow = 0;
        const row = [];
        const table = [];

        for (const day of days) {
            // If current day does not match with its position on the row
            // Add as many empty column as needed to fill the space
            if (day.day !== itemPerRow) {
                row.push(
                    Array.from(Array(day.day).keys()).map((day, idx) => (
                        <td key={`${day}-${idx}`} className={styles.empty} />
                    ))
                );
                // Reset counter according to current day position in row
                itemPerRow = day.day;
            }

            row.push(
                <td key={day.date}>
                    <Day
                        day={day}
                        isSelected={checkIfIsSelected(day)}
                        isFrom={checkIfIsFrom(day)}
                        isTo={checkIfIsTo(day)}
                        isInterval={checkIfIsInterval(day)}
                        onSelect={selectDate}
                    />
                </td>
            );

            ++itemPerRow;
            if (itemPerRow >= 7) {
                table.push(<tr key={day.date}>{Array.from(row)}</tr>);
                row.length = 0;
                itemPerRow = 0;
            }
        }

        if (row.length !== 0) {
            table.push(<tr key={days[0].year}>{Array.from(row)}</tr>);
        }

        return table;
    };

    return (
        <div
            ref={ref}
            tabIndex={-1}
            className={classNames({
                [styles.modal]: true,
                [styles.shown]: props.visibility,
            })}
            style={{
                inset: `${props.position.x}px auto auto ${props.position.y}px`,
            }}
        >
            <header>
                <div
                    className={styles.navArrow}
                    onClick={() => props.prevMonth()}
                >
                    <GoBackIcon size={16} />
                </div>
                <h5>{showCurrentMonthAndYear()}</h5>
                <div
                    className={styles.navArrow}
                    onClick={() => props.nextMonth()}
                >
                    <GoNextIcon size={16} />
                </div>
            </header>
            <ul>
                {DAYS_OF_THE_WEEK.map((day, idx) => (
                    <li key={idx}>{day}</li>
                ))}
            </ul>
            <table>
                <tbody>{buildCalendarRow(props.days)}</tbody>
            </table>
        </div>
    );
});

interface DayProps {
    day: IDate;
    onSelect: (date: IDate) => void;
    isSelected?: boolean;
    isFrom?: boolean;
    isTo?: boolean;
    isInterval?: boolean;
}

const Day = ({
    day,
    onSelect,
    isFrom,
    isTo,
    isInterval,
    isSelected,
}: DayProps) => {
    return (
        <div
            className={classNames({
                [styles.dayContainer]: true,
                [styles.interval]: isInterval,
                [styles.from]: isFrom,
                [styles.to]: isTo,
            })}
        >
            <div
                className={classNames({
                    [styles.day]: true,
                    [styles.selected]: isSelected,
                })}
                onClick={() => onSelect(day)}
            >
                {day.date}
            </div>
        </div>
    );
};
