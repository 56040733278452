import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
} from 'date-fns';

const mapDateToTextDiff = (date: Date): string => {
    const now = new Date();
    const days = differenceInDays(now, date);
    if (days === 1) return 'Hace 1 día';
    if (days > 1) return `Hace ${days} días`;

    const hours = differenceInHours(now, date);
    if (hours === 1) return 'Hace 1 hora';
    if (hours > 1) return `Hace ${hours} horas`;

    const min = differenceInMinutes(now, date);
    if (min === 1) return 'Hace 1 minuto';
    if (min > 1) return `Hace ${min} minutos`;

    return 'Hace un momento';
};

export { mapDateToTextDiff };
