import { useEffect, useRef } from 'react';

import EmergencySound from '../../assets/audios/emergency-ongoing.mp3';

interface Props {
    active: boolean;
}

const EmergencyAudio = (props: Props) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        props.active ? play() : stop();
    }, [props.active]);

    const play = () => {
        audioRef.current!.volume = 1;
        audioRef.current!.play();
    };

    const stop = () => {
        audioRef.current!.volume = 0;
        audioRef.current!.pause();
    };

    return (
        <audio ref={audioRef} controls loop style={{ display: 'none' }}>
            <source src={EmergencySound} />
        </audio>
    );
};

export default EmergencyAudio;
