import { useState } from 'react';

import DropdownIcon from '../../icons/dropdown.icon';

import styles from './status-filter-button.module.scss';

interface Props {
    statusList: { text: string; value: number }[];
    onSelect: (status: { text: string; value: number }) => void;
}

const StatusFilterButton = ({ statusList, onSelect }: Props) => {
    const [selected, setSelected] = useState(statusList[0]);

    const onChange = (value: string) => {
        const selectedValue = statusList.find(
            (status) => status.value === Number(value)
        );
        setSelected(selectedValue!);
        onSelect(selectedValue!);
    };

    return (
        <div className={styles.container}>
            <span>Estado: {selected.text}</span>
            <select
                defaultValue={statusList[0].value}
                onChange={(ev) => onChange(ev.target.value)}
            >
                {statusList.map((status) => (
                    <option key={status.value} value={status.value}>
                        {status.text}
                    </option>
                ))}
            </select>
            <div className={styles.dropdownContainer}>
                <DropdownIcon size={18} color={'#414552'} />
            </div>
        </div>
    );
};

export default StatusFilterButton;
