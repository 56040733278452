import classNames from 'classnames';
import { FormEvent, useEffect, useRef, useState, KeyboardEvent } from 'react';

import TagsInput from '../tags-input/tags-input';
import { useAuthStore, usePetitionStore } from '../../store';
import ArrowBackIcon from '../../icons/arrow-back.icon';
import Divider from '../divider/divider';
import SendIcon from '../../icons/send.icon';
import { PetitionComment } from '../../types/petition-comment.type';
import { petitionSdk } from '../../services/petition.service';
import { mapDateToTextDiff } from '../../utils/date.util';

import styles from './petition-metadata-card.module.scss';

interface Props {
    shown: boolean;
    onDismiss: () => void;
}

let commentsTimer: number;

const PetitionMetadataCard = ({ shown, onDismiss }: Props) => {
    const inputRef = useRef<HTMLSpanElement | null>(null);
    const commentListRef = useRef<HTMLDivElement | null>(null);

    const { accessToken } = useAuthStore();
    const { motives, selected: petition } = usePetitionStore();

    const [sendEnabled, setSendEnabled] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState<PetitionComment[]>([]);
    const [selectedMotives, setSelectedMotives] = useState<number[]>([]);
    const [updateMotivesEnabled, setUpdatedMotivesEnabled] = useState(false);

    useEffect(() => {
        if (petition && petition.comments) {
            setComments(petition.comments);
        }
    }, [petition]);

    useEffect(() => {
        if (comments.length === 0) return;
        scrollCommentListToBottom();
        updateCommentsTime();

        return () => clearInterval(commentsTimer);
    }, [comments]);

    const onInput = (ev: FormEvent<HTMLSpanElement>) => {
        const userInput = (ev.target as HTMLSpanElement).textContent;
        setComment(userInput!.trim());
        setSendEnabled(userInput!.trim().length > 0);
    };

    const detectEnterToSubmit = (ev: KeyboardEvent) => {
        if (ev.key !== 'Enter' || comment.trim().length === 0) return;
        ev.preventDefault();
        return submitComment();
    };

    const onMotiveSelection = (motives: { id: number; name: string }[]) => {
        const petitionMotives =
            petition?.motives?.map((motive) => motive.id).sort() ?? [];
        const selectedMotives = motives.map((motive) => motive.id).sort();
        setUpdatedMotivesEnabled(
            JSON.stringify(petitionMotives) !== JSON.stringify(selectedMotives)
        );
        setSelectedMotives(selectedMotives);
    };

    const onUpdateMotives = async () => {
        setUpdatedMotivesEnabled(false);
        await petitionSdk.updateMotives(
            {
                id: petition!.id,
                motives: selectedMotives,
            },
            accessToken!
        );
    };

    const submitComment = async () => {
        inputRef.current!.textContent = '';
        setSendEnabled(false);
        petitionSdk.addComment(
            {
                id: petition!.id,
                content: comment,
            },
            accessToken!
        );
        setComment('');
        setComments((prev) => [
            ...prev,
            {
                content: comment,
                petitionId: petition!.id,
                authorId: 1,
                createdAt: 'Hace un momento',
            },
        ]);
    };

    const scrollCommentListToBottom = () => {
        setTimeout(() => {
            commentListRef.current?.scrollTo(
                0,
                commentListRef.current?.scrollHeight
            );
        }, 100);
    };

    /**
     * This method allows to update the showed
     * time of each comment (1 minute ago,
     * 2 hours ago, etc...)
     */
    const updateCommentsTime = () => {
        // The trick is to force the re-render simulating a state update
        commentsTimer = window.setInterval(
            () => setComments([...comments]),
            60000
        );
    };

    return (
        <div
            className={classNames({
                [styles.card]: true,
                [styles.shown]: shown,
            })}
        >
            <div className={'row space-between'}>
                <div className={styles.backButton} onClick={() => onDismiss()}>
                    <ArrowBackIcon size={18} color={'#666'} />
                </div>
            </div>
            <h4>Motivos de la emergencia:</h4>
            <div className={'column'} style={{ gap: 10 }}>
                <TagsInput
                    placeholder={'Escribe el motivo'}
                    height={28}
                    tags={motives}
                    preSelectedTags={[]}
                    onSelection={onMotiveSelection}
                />
                {updateMotivesEnabled && (
                    <button
                        className={styles.updateMotivesButton}
                        onClick={onUpdateMotives}
                    >
                        Guardar motivos
                    </button>
                )}
            </div>
            <Divider />
            <h4>Comentarios:</h4>
            {comments.length > 0 && (
                <div ref={commentListRef} className={styles.commentList}>
                    {comments.map((comment, idx) => (
                        <Comment key={idx} comment={comment} />
                    ))}
                </div>
            )}
            <div className={styles.commentsInput}>
                {/* This is the dynamic line-height input */}
                <span
                    ref={inputRef}
                    contentEditable={true}
                    className={styles.input}
                    onInput={onInput}
                    onKeyDown={detectEnterToSubmit}
                />
                <button
                    className={styles.sendButton}
                    disabled={!sendEnabled}
                    onClick={submitComment}
                >
                    <SendIcon size={14} />
                </button>
            </div>
        </div>
    );
};

export default PetitionMetadataCard;

interface CommentProps {
    comment: PetitionComment;
}

const Comment = ({ comment }: CommentProps) => {
    return (
        <div className={styles.comment}>
            <p>{comment.content}</p>
            <span>{mapDateToTextDiff(new Date(comment.createdAt))}</span>
        </div>
    );
};
