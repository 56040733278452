import axios, { AxiosError } from 'axios';

import { ResponseTO } from '../types/response-to.type';
import { PaginatedQuery } from '../types/paginated-query.type';
import { Credentials } from '../types/credentials.type';
import { OperatorCreation } from '../types/operator-creation.type';
import { Company } from '../types/company.type';
import { User, UserCreation } from '../types/user.type';

import { addQueryParams, handleResponseError } from './service';

const BASE_URL = process.env.REACT_APP_USERS_URL;
const ADMIN_URL = process.env.REACT_APP_ADMIN_USERS_URL;
const AUTH_TOKEN = process.env.REACT_APP_API_TOKEN;
const BI_URL = process.env.REACT_APP_BI_URL;

const getUserById = async (id: number): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/users/${id}`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getAll = async (queryParams?: PaginatedQuery): Promise<ResponseTO> => {
    try {
        const inlineParams = addQueryParams(queryParams);
        const response = await axios.get(`${BASE_URL}/users/${inlineParams}`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getUserVehicles = async (id: number): Promise<ResponseTO> => {
    try {
        const response = await axios.get(`${BASE_URL}/users/${id}/vehicles`, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const searchUser = async (query: string): Promise<ResponseTO> => {
    console.log('User Service: searching user');
    try {
        const response = await axios.get(
            `${BI_URL}/users/search?query=${query}`,
            { headers: { Authorization: `Bearer ${AUTH_TOKEN}` } }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const logIn = async (data: Credentials): Promise<ResponseTO> => {
    console.log('User Service: login operator');
    try {
        const response = await axios.post(`${BASE_URL}/auth/operators`, data, {
            headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getOperators = async (
    params?: {
        isActive: boolean;
        role: number;
    },
    token?: string
): Promise<ResponseTO> => {
    console.log('User Service: get operators');
    try {
        const inlineParams = addQueryParams(params);
        const response = await axios.get(
            `${BASE_URL}/operators${inlineParams}`,
            {
                headers: { Authorization: `Bearer ${token ?? AUTH_TOKEN}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getMyProfile = async (token: string): Promise<ResponseTO> => {
    console.log('User Service: get my profile');
    try {
        const response = await axios.get(`${BASE_URL}/operators/me`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const deleteOperator = async (
    id: number,
    token: string
): Promise<ResponseTO> => {
    console.log('User Service: delete operator');
    try {
        const response = await axios.delete(`${ADMIN_URL}/operators/${id}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const createOperator = async (
    data: OperatorCreation,
    token: string
): Promise<ResponseTO> => {
    console.log('User Service: create operator');
    try {
        const response = await axios.post(`${ADMIN_URL}/operators`, data, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const getRoles = async (token: string): Promise<ResponseTO> => {
    console.log('User Service: get roles');
    try {
        const response = await axios.get(`${ADMIN_URL}/roles`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const searchCompaniesByName = async (
    query: string,
    token: string
): Promise<ResponseTO<Company[]>> => {
    console.log('User Service: search companies by name query');
    try {
        console.log(`${ADMIN_URL}/companies/search?name=${query}`);
        const response = await axios.get(
            `${ADMIN_URL}/companies/search?name=${query}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

const createUser = async (
    data: UserCreation,
    auth: { token: string }
): Promise<ResponseTO<User>> => {
    console.log('User Service: create user');
    try {
        const response = await axios.post(`${BASE_URL}/users`, data, {
            headers: { Authorization: `Bearer ${auth.token}` },
        });
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const userSdk = {
    getUserById,
    getAll,
    createUser,
    getUserVehicles,
    searchUser,
    logIn,
    getOperators,
    getMyProfile,
    deleteOperator,
    createOperator,
    getRoles,
    searchCompaniesByName,
};
