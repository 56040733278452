interface Props {
    color?: string;
    size?: number;
}

const CircleCheckIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37256 18.6274 0 12 0C5.37256 0 0 5.37256 0 12C0 18.6274 5.37256 24 12 24ZM4.5 11L8.58997 15L19 5L21 6.82007L8.58997 19L2.5 13L4.5 11Z"
                fill={props.color || '#565454'}
            />
        </svg>
    );
};

export default CircleCheckIcon;
