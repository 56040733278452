interface Props {
    color?: string;
    size?: number;
}

const ExitIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    d="M21 3.00977H3C1.9 3.00977 1 3.90977 1 5.00977V8.99977H3V4.98977H21V19.0198H3V14.9998H1V19.0098C1 20.1098 1.9 20.9898 3 20.9898H21C22.1 20.9898 23 20.1098 23 19.0098V5.00977C23 3.89977 22.1 3.00977 21 3.00977Z"
                    fill={props.color || '#565454'}
                />
                <path
                    d="M11 16L15 12L11 8V11H1V13H11V16Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default ExitIcon;
