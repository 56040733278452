interface Props {
    color?: string;
    size?: number;
}

const ArrowLeftIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    id="icon/navigation/arrow_back_24px"
                    d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default ArrowLeftIcon;
