import { useEffect } from 'react';

import { useOperatorStore, usePetitionStore } from '../store';

export const useFetchBaseConfig = () => {
    const petitionState = usePetitionStore();
    const operatorsState = useOperatorStore();

    useEffect(() => {
        petitionState.fetchActives();
        petitionState.fetchReasons();
        operatorsState.fetchActiveOperators();
    }, []);
};
