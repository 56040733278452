interface Props {
    className?: string;
    color?: string;
    size?: number;
    onClick?: () => void;
}

const GoBackIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            className={props.className}
            fill="none"
            onClick={() => props.onClick?.()}
        >
            <path
                d="M8.49988 12.3572L7.70587 12.9652C7.44934 12.6301 7.43075 12.17 7.65942 11.8154L8.49988 12.3572ZM14.6594 0.958246C14.9587 0.494073 15.5776 0.360392 16.0418 0.659662C16.5059 0.958931 16.6396 1.57782 16.3403 2.04199L14.6594 0.958246ZM16.2939 20.8922C16.6296 21.3307 16.5463 21.9584 16.1078 22.2941C15.6693 22.6298 15.0416 22.5465 14.7059 22.108L16.2939 20.8922ZM7.65942 11.8154L14.6594 0.958246L16.3403 2.04199L9.34034 12.8991L7.65942 11.8154ZM9.29389 11.7493L16.2939 20.8922L14.7059 22.108L7.70587 12.9652L9.29389 11.7493Z"
                fill={props.color || '#585970'}
            />
        </svg>
    );
};

export default GoBackIcon;
