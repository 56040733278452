import { PropsWithChildren } from 'react';

import styles from './checkbox.module.scss';

interface Props extends PropsWithChildren<any> {
    id: string | number;
    checked?: boolean;
    onChange?: () => void;
}

const Checkbox = ({ id, children, checked, onChange }: Props) => {
    return (
        <div className={styles.checkbox}>
            <input
                value={id}
                type={'checkbox'}
                checked={checked}
                onChange={onChange}
                readOnly
            />
            <label>{children}</label>
        </div>
    );
};

export default Checkbox;
