import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { usePetitionStore, useSettingsStore } from '../../store';
import Table from '../../components/table/table.component';
import { Petition } from '../../types/petition.type';
import { PetitionStatusColors } from '../../utils/petition-status.util';
import { useNavigation } from '../../hooks/navigation.hook';
import DatePicker from '../../components/date-picker/date-picker.component';
import StatusFilterButton from '../../components/status-filter-button/status-filter-button';

const PetitionsHistoryView = () => {
    const { navigate } = useNavigation();
    const { theme } = useSettingsStore();
    const petitionState = usePetitionStore();
    const [rows, setRows] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [filters, setFilters] = useState({});

    useEffect(() => {
        petitionState.fetchAll({
            count: pagination.count,
            page: pagination.currentPage,
            filters: filters,
        });
    }, [pagination.currentPage, filters]);

    useEffect(() => {
        mapPetitions();
    }, [petitionState.historyList]);

    const mapStatus = (petition: Petition) => {
        if (!petition.isFinished && !petition.cancellationReasonId)
            return PetitionStatusColors.ACTIVE;
        if (petition.isFinished) return PetitionStatusColors.COMPLETED;

        return PetitionStatusColors.CANCELLED;
    };

    const mapDirection = (direction: string) => {
        const text = direction.substring(0, 25);
        return `${text}${direction.length > 25 ? '...' : ''}`;
    };

    const mapPetitions = () => {
        setPagination((prev) => ({
            ...prev,
            currentPage: petitionState.historyList.page,
            totalPages:
                petitionState.historyList.count === 0
                    ? 1
                    : Math.ceil(
                          petitionState.historyList.total / pagination.count
                      ),
        }));
        setRows(
            petitionState.historyList.result.map((petition) => ({
                id: petition.id,
                data: [
                    { type: 'number', value: petition.id },
                    {
                        type: 'string',
                        value: mapDirection(petition.googleReferencePoint),
                    },
                    {
                        type: 'string',
                        value: `${petition.user?.firstName ?? '-'} ${
                            petition.user?.lastName ?? '-'
                        }`,
                    },
                    { type: 'status', value: mapStatus(petition) },
                    { type: 'date', value: new Date(petition.createdAt) },
                ],
            }))
        );
    };

    const onPageChange = (page: number) => {
        if (page < 1) return;
        setPagination((prev) => ({ ...prev, currentPage: page }));
    };

    const onPetitionSelected = (id: number) => {
        navigate(`/petitions/${id}`);
    };

    const addFilter = (field: string, value: string) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };

    const clearFilter = (field: string) => {
        setFilters((prev) => ({ ...prev, [field]: undefined }));
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Solicitudes de emergencia</h1>
                <div className={'flex align-center'} style={{ gap: 8 }}>
                    <DatePicker
                        onDate={(date) =>
                            addFilter('createdAt', format(date, 'yyyy-MM-dd'))
                        }
                        clearable={true}
                        onClear={() => clearFilter('createdAt')}
                    />
                    <StatusFilterButton
                        statusList={[
                            { value: -1, text: 'Todas' },
                            { value: 1, text: 'Activas' },
                            { value: 2, text: 'Culminadas' },
                            { value: 3, text: 'Canceladas' },
                        ]}
                        onSelect={(status) =>
                            status.value === -1
                                ? clearFilter('status')
                                : addFilter('status', status.value.toString())
                        }
                    />
                </div>
                <Table
                    header={[
                        'ID',
                        'Dirección',
                        'Usuario',
                        'Estado',
                        'Fecha de solicitud',
                    ]}
                    data={rows}
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={onPageChange}
                    onItemSelected={onPetitionSelected}
                />
            </div>
        </main>
    );
};

export default PetitionsHistoryView;
