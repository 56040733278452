import styles from './metric-card.module.scss';

interface Props {
    label: string;
    value: number;
}

const MetricCard = ({ label, value }: Props) => {
    return (
        <div className={styles.metricCard}>
            <h5>{label}</h5>
            <b>{value}</b>
        </div>
    );
};

export default MetricCard;
