import { ChangeEvent, useState } from 'react';

import Modal from '../modal/modal';
import { CancellationReason } from '../../types/cancellation-reason.type';

import styles from './reasons-modal.module.scss';

interface Props {
    reasons: CancellationReason[];
    shown: boolean;
    onDiscard: () => void;
    onConfirm: (id: number) => void;
}

const ReasonsModal = ({ shown, reasons, onDiscard, onConfirm }: Props) => {
    const [selectedReason, setSelectedReason] = useState<number>(-1);

    const onSelect = (ev: ChangeEvent<HTMLSelectElement>) => {
        setSelectedReason(Number(ev.target.value));
    };

    const confirmCancellation = () => {
        if (!selectedReason) return;
        onConfirm(selectedReason);
    };

    return (
        <Modal shown={shown} onDiscard={onDiscard}>
            <div className={styles.content}>
                <h6>Selecciona una razón</h6>
                <span>
                    Escoge cuál es la razón por la cual esta solicitud debe ser
                    cancelada
                </span>
                <select onChange={onSelect} value={selectedReason}>
                    <option disabled={true} value={-1}>
                        Selecciona una razón
                    </option>
                    {reasons.map((reason) => (
                        <option key={reason.id} value={reason.id}>
                            {reason.reason}
                        </option>
                    ))}
                </select>
                <div className={styles.actions}>
                    <button className={styles.discard} onClick={onDiscard}>
                        Cerrar este modal
                    </button>
                    <button
                        className={styles.confirm}
                        onClick={confirmCancellation}
                    >
                        Cancelar solicitud
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ReasonsModal;
