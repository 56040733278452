import { useEffect } from 'react';

import { NavigationFunction } from './navigation.hook';

export const useEventHooks = (navigate: NavigationFunction) => {
    const listenEvents = () => {
        window.addEventListener('open:createPetition', () =>
            navigate('/create-petition', { replace: true })
        );
    };

    useEffect(() => {
        listenEvents();
    }, []);
};
