interface Props {
    className?: string;
    color?: string;
    size?: number;
    onClick?: () => void;
}

const GoNextIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 9 24"
            className={props.className}
            fill="none"
            onClick={() => props.onClick?.()}
        >
            <path
                d="M8 12.3571L8.79401 12.9651C9.05054 12.63 9.06913 12.1699 8.84046 11.8153L8 12.3571ZM1.84046 0.958125C1.54119 0.493952 0.922298 0.360271 0.458125 0.659541C-0.00604723 0.95881 -0.139729 1.5777 0.159541 2.04187L1.84046 0.958125ZM0.205994 20.8921C-0.129746 21.3306 -0.0464286 21.9583 0.392088 22.294C0.830606 22.6297 1.45826 22.5464 1.794 22.1079L0.205994 20.8921ZM8.84046 11.8153L1.84046 0.958125L0.159541 2.04187L7.15954 12.899L8.84046 11.8153ZM7.20599 11.7492L0.205994 20.8921L1.794 22.1079L8.79401 12.9651L7.20599 11.7492Z"
                fill={props.color || '#585970'}
            />
        </svg>
    );
};

export default GoNextIcon;
