import { MouseEvent, ReactNode, useEffect, useState } from 'react';

import { ThemeType } from '../../types/theme.type';
import pkg from '../../../package.json';
import CollapseIcon from '../../icons/collapse.icon';
import ExpandIcon from '../../icons/expand.icon';
import { useNavigation } from '../../hooks/navigation.hook';
import { useAuthStore, useSettingsStore } from '../../store';
import ExitIcon from '../../icons/exit.icon';
import { useIsCoordinator } from '../../hooks/is-coordinator.hook';
import { uuid } from '../../utils/uuid.util';

import styles from './main-menu.module.scss';
import { menuItems } from './menu-items-config';

interface Props {
    theme: ThemeType;
    onLogout: () => void;
}

const MainMenu = ({ theme = 'light', onLogout }: Props) => {
    const { navigate } = useNavigation();
    const { menuCollapsable } = useSettingsStore();
    const { profile } = useAuthStore();
    useIsCoordinator(profile!);

    const [expanded, setExpanded] = useState(true);
    const [buttonIconColor, setButtonColorIcon] = useState('#565454');

    useEffect(() => {
        setExpanded(menuCollapsable);
    }, [menuCollapsable]);

    useEffect(() => {
        setButtonColorIcon(theme === 'light' ? '#565454' : 'white');
    }, [theme]);

    const toggle = (ev: MouseEvent) => {
        ev.preventDefault();
        setExpanded(!expanded);
    };

    return (
        <nav
            id={styles.appMenu}
            style={{ maxWidth: expanded ? 200 : 60 }}
            className={theme === 'light' ? styles.light : styles.dark}
        >
            <ul>
                {profile?.role?.name &&
                    menuItems
                        .filter((item) =>
                            item.roles.includes(profile.role.name)
                        )
                        .map((item) => (
                            <MenuItem
                                key={uuid()}
                                text={item.text}
                                icon={item.icon}
                                expanded={expanded}
                                onClick={() =>
                                    navigate(item.path, {
                                        targetBlank: item.targetBlank,
                                    })
                                }
                            />
                        ))}
            </ul>
            {menuCollapsable && (
                <button className={styles.collapseButton} onClick={toggle}>
                    {expanded ? (
                        <CollapseIcon size={20} color={buttonIconColor} />
                    ) : (
                        <ExpandIcon size={20} color={buttonIconColor} />
                    )}
                </button>
            )}
            <div className={'column'}>
                <span className={styles.logoutButton} onClick={onLogout}>
                    {expanded ? 'Cerrar sesión' : <ExitIcon size={24} />}
                </span>
                <span className={styles.version}>
                    {expanded && 'Versión: '}v{pkg.version}
                </span>
            </div>
        </nav>
    );
};

interface MenuItemProps {
    text: string;
    onClick: () => void;
    icon: ReactNode;
    expanded: boolean;
}

const MenuItem = (props: MenuItemProps) => {
    return (
        <li
            onClick={props.onClick}
            style={{ justifyContent: !props.expanded ? 'center' : 'initial' }}
        >
            {props.expanded ? props.text : props.icon}
        </li>
    );
};

export default MainMenu;
