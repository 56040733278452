import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { PetitionMotive } from '../../../types/petition-motive.type';
import { uuid } from '../../../utils/uuid.util';
import { normalize } from '../../../utils/string.util';

import styles from './reason-selection.module.scss';

interface Props {
    motives: PetitionMotive[];
    onSelection: (motives: PetitionMotive[]) => void;
}

type Motive = PetitionMotive & { selected: boolean };

const ReasonSelection = ({ motives, onSelection }: Props) => {
    const [listShown, setListShown] = useState(false);
    const [selectedMotives, setSelectedMotives] = useState<Motive[]>([]);
    const [query, setQuery] = useState('');

    useEffect(() => {
        setSelectedMotives(
            motives.map((motive) => ({
                id: motive.id,
                name: motive.name,
                selected: false,
            }))
        );
    }, [motives]);

    useEffect(() => {
        onSelection(selectedMotives.filter((motive) => motive.selected));
    }, [selectedMotives]);

    const filterBySearch = (motives: Motive[], query?: string): Motive[] => {
        if (!query || query.trim().length === 0) return motives;
        return motives.filter((motive) =>
            normalize(motive.name).includes(normalize(query))
        );
    };

    const toggleMotive = (id: number) => {
        setSelectedMotives(
            selectedMotives.map((motive) => {
                if (motive.id === id) motive.selected = !motive.selected;
                return motive;
            })
        );
    };

    return (
        <section>
            <label>Razón de la emergencia</label>
            <div className={styles.formSection}>
                <span>
                    Puedes escoger una o más razones por la cual se realiza esta
                    emergencia. <i>Este apartado es opcional.</i>
                </span>
                <div className={styles.selector}>
                    {listShown && (
                        <div
                            className={styles.blockLayer}
                            onClick={() => setListShown(false)}
                        />
                    )}
                    <input
                        placeholder={'Escribe el motivo que buscas'}
                        type={'text'}
                        style={{
                            position: 'relative',
                            borderRadius: listShown ? '6px 6px 0 0' : '6px',
                            zIndex: listShown ? 1 : 'inherit',
                        }}
                        onClick={() => setListShown(true)}
                        onChange={(ev) => setQuery(ev.target.value.trim())}
                    />
                    {listShown && (
                        <ul className={styles.list}>
                            {filterBySearch(selectedMotives, query).map(
                                (motive) => (
                                    <li
                                        key={uuid()}
                                        onClick={() => toggleMotive(motive.id)}
                                    >
                                        <div
                                            className={classNames({
                                                [styles.checkbox]: true,
                                                [styles.selected]:
                                                    motive.selected,
                                            })}
                                        />
                                        <span>{motive.name}</span>
                                    </li>
                                )
                            )}
                        </ul>
                    )}
                </div>
            </div>
        </section>
    );
};

export default ReasonSelection;
