interface Props {
    color?: string;
    size?: number;
}

const ArrowBackIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 44}
            height={props.size || 44}
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke={props.color || '#565454'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M9 14l-4 -4l4 -4" />
            <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
        </svg>
    );
};

export default ArrowBackIcon;
