import { PropsWithChildren, useState } from 'react';

import styles from './copy-link.module.scss';
import LinkIcon from './link.svg';

interface Props extends PropsWithChildren {
    link: string;
}

const CopyLink = ({ children, link }: Props) => {
    const [copied, setCopied] = useState(false);

    const toClipboard = async (link: string) => {
        await navigator.clipboard.writeText(link);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 1000);
    };

    return copied ? (
        <span className={styles.copied}>¡Copiado!</span>
    ) : (
        <span className={styles.link} onClick={() => toClipboard(link)}>
            {children}
            <img src={LinkIcon} />
        </span>
    );
};

export default CopyLink;
