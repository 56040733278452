import { create } from 'zustand';

import { Petition } from './types/petition.type';
import ResponseCodes from './constants/response-codes.constant';
import { GetAllFilters, petitionSdk } from './services/petition.service';
import { PaginatedQuery } from './types/paginated-query.type';
import { User } from './types/user.type';
import { userSdk } from './services/user.service';
import { Vehicle } from './types/vehicle.type';
import Logger from './utils/logger.util';
import { CancellationReason } from './types/cancellation-reason.type';
import { ThemeType } from './types/theme.type';
import UserRoles from './constants/user-roles.constant';
import { Operator } from './types/operator.type';
import { PetitionMotive } from './types/petition-motive.type';
import { Credentials } from './types/credentials.type';
import { OperatorCreation } from './types/operator-creation.type';
import { Role } from './types/role.type';
import { getOS } from './utils/os.util';

const macShortcutKey = {
    icon: '⌘',
    key: 'Meta',
};
const windowsShortcutKey = {
    icon: 'Ctrl',
    key: 'Control',
};

const PRIMARY_SHORTCUT_KEY =
    getOS() === 'mac' ? macShortcutKey : windowsShortcutKey;

const getProfileFromStorage = (): Operator | undefined => {
    const profile = localStorage.getItem('profile');
    if (!profile || profile.length === 0) return undefined;
    return JSON.parse(profile);
};

interface AuthState {
    profile?: Operator;
    accessToken?: string;
    login: (creds: Credentials) => Promise<{ error?: string }>;
    logout: () => void;
    fetchProfile: () => Promise<void>;
}

export const useAuthStore = create<AuthState>((set, get) => ({
    profile: getProfileFromStorage(),
    accessToken: localStorage.getItem('accessToken') ?? undefined,
    login: async (creds) => {
        const response = await userSdk.logIn(creds);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return { error: response.message };
        }
        set({ profile: response.data, accessToken: response.data.accessToken });
        localStorage.setItem('accessToken', response.data.accessToken);
        return {};
    },
    logout: () => {
        set({ profile: undefined, accessToken: undefined });
        localStorage.removeItem('profile');
        localStorage.removeItem('accessToken');
    },
    fetchProfile: async () => {
        if (get().profile) return;
        const response = await userSdk.getMyProfile(get().accessToken!);
        if (response.code != ResponseCodes.PROCESS_OK) return;
        set({ profile: response.data });
    },
}));

interface PetitionState {
    historyList: {
        page: number;
        total: number;
        count: number;
        result: Petition[];
    };
    actives: Petition[];
    selected?: Petition;
    alertNewOne: boolean;
    cancellationReasons: CancellationReason[];
    motives: PetitionMotive[];
    fetchActives: () => void;
    addNewActive: (newOne: Petition) => void;
    removeFromActives: (id: number) => void;
    selectPetition: (petition?: Petition) => void;
    fetchAll: (params?: GetAllFilters) => void;
    fetchById: (id: number) => Promise<Petition | null>;
    updateStatus: (id: number, status: number) => void;
    stopAlert: () => void;
    discardPetition: (id: number) => void;
    fetchReasons: () => void;
    cancelPetition: (id: number, reason?: number) => void;
    finishPetition: (id: number) => void;
    fetchMotives: (token: string) => void;
}

export const usePetitionStore = create<PetitionState>((set, get) => ({
    actives: [],
    historyList: { page: 1, total: 0, count: 10, result: [] },
    selected: undefined,
    alertNewOne: false,
    cancellationReasons: [],
    motives: [],
    fetchActives: async () => {
        const response = await petitionSdk.getActives();
        response.code === ResponseCodes.PROCESS_OK &&
            set({ actives: response.data });
    },
    addNewActive: (newOne: Petition) => {
        const actives = get().actives;
        const exists = actives.find((active) => active.id === newOne.id);
        if (!exists) {
            set({ actives: [...actives, newOne], alertNewOne: true });
        }
    },
    removeFromActives: (id: number) => {
        const filtered = get().actives.filter((petition) => petition.id !== id);
        set({ actives: filtered });
    },
    selectPetition: (petition?: Petition) => set({ selected: petition }),
    fetchAll: async (params?: GetAllFilters) => {
        const response = await petitionSdk.getAll(params);
        response.code === ResponseCodes.PROCESS_OK &&
            set({ historyList: response.data });
    },
    fetchById: async (id: number) => {
        const response = await petitionSdk.getById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            Logger.error('[!] Error fetching petition:', response);
            return null;
        }
        return response.data as Petition;
    },
    updateStatus: async (id: number, status: number) => {
        const petitions = get().actives.map((petition) => {
            if (petition.id === id) {
                petition.status = status;
            }
            return petition;
        });
        set({ actives: petitions });
    },
    stopAlert: () => set({ alertNewOne: false }),
    discardPetition: (id: number) => {
        const petitions = get().actives.filter(
            (petition) => petition.id !== id
        );
        set({ actives: petitions });
        if (get().selected?.id === id) {
            set({ selected: undefined });
        }
    },
    fetchReasons: async () => {
        const response = await petitionSdk.getCancellationReasons();
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        set({ cancellationReasons: response.data });
    },
    cancelPetition: (id: number, reason?: number) => {
        const petitions = get().actives.map((petition) => {
            if (petition.id === id) {
                petition.isCancelled = true;
                petition.cancellationReasonId = reason;
            }
            return petition;
        });
        set({ actives: petitions, alertNewOne: false });
        if (get().selected?.id === id) {
            set({
                selected: {
                    ...get().selected!,
                    isCancelled: true,
                    cancellationReasonId: reason,
                },
            });
        }
    },
    finishPetition: (id: number) => {
        const petitions = get().actives.map((petition) => {
            if (petition.id === id) {
                petition.status = 5;
                petition.isFinished = true;
            }
            return petition;
        });
        set({ actives: petitions });
        if (get().selected?.id === id) {
            set({
                selected: {
                    ...get().selected!,
                    status: 5,
                    isFinished: true,
                },
            });
        }
    },
    fetchMotives: async (token: string) => {
        const response = await petitionSdk.getPetitionMotives(token);
        if (response.code !== ResponseCodes.PROCESS_OK)
            return set({ motives: [] });
        set({ motives: response.data });
    },
}));

interface UserState {
    list: {
        page: number;
        total: number;
        count: number;
        result: User[];
    };
    fetchAll: (params?: PaginatedQuery & { search?: string }) => void;
    getById: (id: number) => Promise<User | null>;
    getUserVehicles: (id: number) => Promise<Vehicle[]>;
}

export const useUserStore = create<UserState>((set) => ({
    list: { page: 1, total: 0, count: 10, result: [] },
    fetchAll: async (params?: PaginatedQuery) => {
        const response = await userSdk.getAll(params);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return set({ list: { page: 1, count: 0, result: [], total: 0 } });
        }
        set({ list: response.data });
    },
    getById: async (id: number) => {
        const response = await userSdk.getUserById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            Logger.error('[!] Error fetching user:', response);
            return null;
        }
        return response.data as User;
    },
    getUserVehicles: async (id: number) => {
        const response = await userSdk.getUserVehicles(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            Logger.error('[!] Error fetching user vehicles:', response);
            return [];
        }
        return response.data as Vehicle[];
    },
}));

interface OperatorState {
    operators: Operator[];
    employees: Operator[];
    roles: Role[];
    fetchActiveOperators: () => Promise<void>;
    fetchAllEmployees: (token: string) => Promise<void>;
    deleteOperator: (id: number, token: string) => Promise<void>;
    createOperator: (
        data: OperatorCreation,
        token: string
    ) => Promise<{ error?: string }>;
    getRoles: (token: string) => Promise<void>;
}

export const useOperatorStore = create<OperatorState>((set, get) => ({
    operators: [],
    employees: [],
    roles: [],
    fetchActiveOperators: async () => {
        const response = await userSdk.getOperators({
            isActive: true,
            role: UserRoles.OPERATOR,
        });
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        set({ operators: response.data });
    },
    fetchAllEmployees: async (token) => {
        const response = await userSdk.getOperators(undefined, token);
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        set({ employees: response.data });
    },
    deleteOperator: async (id, token) => {
        const response = await userSdk.deleteOperator(id, token);
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        const filteredList = get().employees.filter(
            (employee) => employee.id !== id
        );
        set({ employees: filteredList });
    },
    createOperator: async (data, token) => {
        const response = await userSdk.createOperator(data, token);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return { error: response.message };
        }
        return {};
    },
    getRoles: async (token) => {
        const response = await userSdk.getRoles(token);
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        set({ roles: response.data });
    },
}));

interface SettingsState {
    theme: {
        current: ThemeType;
        change: (theme: ThemeType) => void;
    };
    menuCollapsable: boolean;
    toggleMenuCollapsable: () => void;
    shortcuts: {
        displayText: string;
        keys: string[];
        action: (key: string, holdingKey?: { [key: string]: boolean }) => void;
    }[];
    initShortcuts: () => void;
}

export const useSettingsStore = create<SettingsState>((set, get) => ({
    theme: {
        current: (localStorage.getItem('theme') as ThemeType) ?? 'light',
        change: (themeValue) => {
            localStorage.setItem('theme', themeValue);
            set({ theme: { ...get().theme, current: themeValue } });
        },
    },
    menuCollapsable: true,
    toggleMenuCollapsable: () =>
        set({ menuCollapsable: !get().menuCollapsable }),
    shortcuts: [
        {
            displayText: 'Crear solicitud',
            keys: [PRIMARY_SHORTCUT_KEY.icon, 'E'],
            action: (key: string, holdingKey?: { [key: string]: boolean }) => {
                holdingKey![PRIMARY_SHORTCUT_KEY.key] &&
                    key === 'e' &&
                    window.dispatchEvent(new Event('open:createPetition'));
            },
        },
        {
            displayText: 'Mostrar buscador',
            keys: [PRIMARY_SHORTCUT_KEY.icon, 'B'],
            action: (key: string, holdingKey?: { [key: string]: boolean }) => {
                holdingKey![PRIMARY_SHORTCUT_KEY.key] &&
                    key === 'b' &&
                    window.dispatchEvent(new Event('open:search'));
            },
        },
    ],
    initShortcuts: () => {
        const keyPressed: { [key: string]: boolean } = {};
        window.addEventListener('keydown', (ev) => {
            keyPressed[ev.key] = true;
            get().shortcuts.forEach((shortcut) => {
                shortcut.action(ev.key, keyPressed);
            });
        });
        window.addEventListener('keyup', (ev) => {
            delete keyPressed[ev.key];
        });
    },
}));

interface TutorialState {
    showTutorial: boolean;
    lastIndexTipSeen: number;
    setShowTutorial: (shown: boolean) => void;
    setLastIndexTipSeen: (index: number) => void;
}

export const useTutorialStore = create<TutorialState>((set) => ({
    showTutorial: true,
    lastIndexTipSeen: 0,
    setShowTutorial: (show: boolean) => set({ showTutorial: show }),
    setLastIndexTipSeen: (index: number) => set({ lastIndexTipSeen: index }),
}));
