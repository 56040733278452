import { Doughnut } from 'react-chartjs-2';

import { uuid } from '../../../utils/uuid.util';
import SadFaceIcon from '../../../icons/sad-face.icon';

import styles from './doughnut-chat.module.scss';

const chartOptions = {
    plugins: { legend: { display: false } },
};

interface Props {
    title: string;
    data: Record<string, number>;
}

const COLORS = [
    '#9ce388',
    '#88ace3',
    '#e38888',
    '#e3b688',
    '#e388db',
    '#e0e388',
];

const DoughnutChart = ({ title, data }: Props) => {
    if (Object.keys(data).length === 0) {
        return (
            <div className={styles.container}>
                <h5>{title}</h5>
                <div
                    style={{
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <SadFaceIcon color={'#5c636b'} />
                    <h6>No hay datos generar un gráfico</h6>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h5>{title}</h5>
            <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                <div style={{ width: 200, height: 200 }}>
                    <Doughnut
                        options={chartOptions}
                        data={{
                            labels: Object.keys(data),
                            datasets: [
                                {
                                    data: Object.values(data),
                                    backgroundColor: COLORS,
                                },
                            ],
                        }}
                    />
                </div>
                <ul>
                    {Object.keys(data).map((key, idx) => (
                        <li key={uuid()}>
                            <div
                                className={styles.itemColor}
                                style={{
                                    backgroundColor: COLORS[idx],
                                }}
                            />
                            {key}: {data[key]}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DoughnutChart;
