import { JSX, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import GoBack from '../../components/go-back/go-back';
import { User } from '../../types/user.type';
import { useSettingsStore, useUserStore } from '../../store';
import { Vehicle } from '../../types/vehicle.type';
import { mapUserHasSubscription } from '../../utils/user-subscription.util';
import SubscriptionStatus from '../../constants/subscription.status';

import styles from './user-details.module.scss';

const UserDetailsView = () => {
    const { ['id']: userId } = useParams();
    const { theme } = useSettingsStore();
    const userStore = useUserStore();
    const [user, setUser] = useState<User>();
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);

    useEffect(() => {
        fetchUser();
    }, []);

    useEffect(() => {
        user && fetchVehicles();
    }, [user]);

    const fetchUser = async () => {
        const user = await userStore.getById(Number(userId));
        user && setUser(user);
    };

    const fetchVehicles = async () => {
        setVehicles(await userStore.getUserVehicles(user!.id));
    };

    const renderVehicles = (vehicles: Vehicle[]): JSX.Element => {
        return (
            <ul className={styles.vehicleList}>
                {vehicles.map((vehicle) => (
                    <li>
                        <b>
                            {vehicle.brand} {vehicle.model}
                            {' - '}
                            {` Color: ${vehicle.color}`}
                        </b>
                        <span>Placa: {vehicle.plate ?? '(Sin definir)'}</span>
                    </li>
                ))}
            </ul>
        );
    };

    const renderSubscriptionStatus = (user: User) => {
        const { value, txtColor, bgColor, expiration } =
            mapUserHasSubscription(user);
        const plan = user.subscriptions?.find(
            (sub) => sub.status === SubscriptionStatus.ACTIVE
        )?.service;
        return (
            <>
                <div
                    style={{
                        color: txtColor,
                        backgroundColor: bgColor,
                    }}
                    className={styles.subscriptionPill}
                >
                    Suscripción: {value}
                </div>
                {plan && (
                    <div className={'column'} style={{ marginTop: 10, gap: 4 }}>
                        <span className={styles.subInfo}>
                            <b>Vencimiento:</b> {expiration}
                        </span>
                        <span className={styles.subInfo}>
                            <b>Plan:</b> {plan?.name} - {plan?.code}
                        </span>
                    </div>
                )}
            </>
        );
    };

    return (
        <main className={'view'}>
            <div className={'viewContent'}>
                <header>
                    <GoBack theme={theme.current} />
                    <h1 className={styles.title}>Usuario {user?.id}</h1>
                </header>
                {user && (
                    <section className={styles.infoContainer}>
                        <div>{renderSubscriptionStatus(user)}</div>
                        <div className={styles.infoSection}>
                            <h5>Detalles del usuario</h5>
                            <div className={styles.infoRow}>
                                <b>Nombre:</b>
                                <span>
                                    {user.firstName} {user.lastName}
                                </span>
                            </div>
                            <div className={styles.infoRow}>
                                <b>Cédula:</b>
                                <span>
                                    {user.documentType}-{user.document}
                                </span>
                            </div>
                            <div className={styles.infoRow}>
                                <b>Teléfono:</b>
                                <span>{user.phoneNumber}</span>
                            </div>
                            <div className={styles.infoRow}>
                                <b>Correo:</b>
                                <span>{user.email}</span>
                            </div>
                        </div>

                        {user.company && (
                            <div className={styles.infoSection}>
                                <h5>Compañía</h5>
                                <div className={styles.infoRow}>
                                    <b>Nombre de la compañía:</b>
                                    <span>{user.company.displayName}</span>
                                </div>
                            </div>
                        )}

                        <div className={styles.infoSection}>
                            <h5>Vehículos</h5>
                            {vehicles.length > 0 ? (
                                renderVehicles(vehicles)
                            ) : (
                                <i className={styles.noVehiclesText}>
                                    Este usuario no tiene vehículos registrados.
                                </i>
                            )}
                        </div>
                    </section>
                )}
            </div>
        </main>
    );
};

export default UserDetailsView;
