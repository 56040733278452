import axios, { AxiosError } from 'axios';

import { ResponseTO } from '../types/response-to.type';
import { PetitionsReport } from '../types/petitions-report.type';

import { addQueryParams, handleResponseError } from './service';

const BASE_URL = process.env.REACT_APP_BI_URL;

const getPetitionsReport = async (
    token: string,
    params?: Record<string, string>
): Promise<ResponseTO<PetitionsReport>> => {
    try {
        const queryParams = addQueryParams(params);
        const response = await axios.get(
            `${BASE_URL}/petitions/general-report${queryParams}`,
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );
        return response.data;
    } catch (err) {
        return handleResponseError(err as AxiosError);
    }
};

export const biSDK = {
    getPetitionsReport,
};
