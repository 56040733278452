import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useAuthStore, useSettingsStore } from '../../store';
import DatePicker from '../../components/date-picker/date-picker.component';
import SearchCompanyInput from '../../components/search-company-input/search-company-input';
import { userSdk } from '../../services/user.service';
import { Company } from '../../types/company.type';
import ResponseCodes from '../../constants/response-codes.constant';
import { DateRange } from '../../types/date-range.type';
import { biSDK } from '../../services/bi.service';
import { PetitionsReport } from '../../types/petitions-report.type';
import Divider from '../../components/divider/divider';
import DownloadIcon from '../../icons/download.icon';

import MetricCard from './components/metric-card';
import DoughnutChart from './components/doughnut-chart';
import LineChart from './components/line-chart';
import styles from './petitions-report.module.scss';

const PetitionsReportView = () => {
    const { accessToken } = useAuthStore();
    const { theme } = useSettingsStore();

    const [showButtons, setShowButtons] = useState(true);
    const [filters, setFilters] = useState<Record<string, string>>({});
    const [companiesList, setCompaniesList] = useState<Company[]>([]);
    const [report, setReport] = useState<PetitionsReport>();

    useEffect(() => {
        accessToken && fetchReport(filters, accessToken);
    }, [filters, accessToken]);

    const fetchReport = async (
        filters: Record<string, string>,
        token: string
    ) => {
        const response = await biSDK.getPetitionsReport(token, filters);
        if (response.code !== ResponseCodes.PROCESS_OK) return;
        setReport(response.data);
    };

    const addFilter = (field: string, value: string) => {
        setFilters((prev) => ({ ...prev, [field]: value }));
    };

    const addDateRangeFilter = (range: DateRange) => {
        setFilters((prev) => ({
            ...prev,
            from: format(range.from!, 'yyyy-MM-dd'),
            to: format(range.to!, 'yyyy-MM-dd'),
        }));
    };

    const clearFilters = (...fields: string[]) => {
        const cleared = { ...filters };
        for (const field of fields) {
            delete cleared[field];
        }
        setFilters(cleared);
    };

    const searchCompanies = async (query: string) => {
        if (!query || query.length === 0) return;
        const response = await userSdk.searchCompaniesByName(
            query,
            accessToken!
        );
        console.log(response);
        if (response.code === ResponseCodes.PROCESS_OK) {
            setCompaniesList(response.data!);
        }
    };

    const printPDF = async () => {
        setShowButtons(false);
        setTimeout(() => {
            window.print();
            setShowButtons(true);
        }, 100);
    };

    return (
        <main
            className={classNames([
                'view',
                styles.reportView,
                theme.current === 'dark' && 'dark',
            ])}
            style={{ backgroundColor: '#f2f4f9' }}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Reporte general de solicitudes</h1>
                {showButtons && (
                    <div className={'flex align-center'} style={{ gap: 8 }}>
                        <DatePicker
                            clearable={true}
                            onDate={(date) =>
                                addFilter(
                                    'createdAt',
                                    format(date, 'yyyy-MM-dd')
                                )
                            }
                            isRange={true}
                            onRange={addDateRangeFilter}
                            onClear={() => clearFilters('from', 'to')}
                        />
                        <SearchCompanyInput
                            onSearch={searchCompanies}
                            list={companiesList}
                            onSelect={(id) =>
                                addFilter('teamId', id.toString())
                            }
                            onClear={() => clearFilters('teamId')}
                        />
                        <button className={styles.pdfButton} onClick={printPDF}>
                            <DownloadIcon size={18} />
                            <span>Generar PDF</span>
                        </button>
                    </div>
                )}
                <div style={{ display: 'flex', gap: 4, flex: 1 }}>
                    <MetricCard
                        label={'Total solicitudes'}
                        value={report?.total ?? 0}
                    />
                    <MetricCard
                        label={'Total completadas'}
                        value={report?.byStatus?.completed ?? 0}
                    />
                    <MetricCard
                        label={'Total canceladas'}
                        value={report?.byStatus?.cancelled ?? 0}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: 16,
                        marginTop: 20,
                    }}
                >
                    <DoughnutChart
                        title={'Razones de cancelación'}
                        data={report?.byCancellation ?? {}}
                    />
                    <Divider orientation={'vertical'} />
                    <DoughnutChart
                        title={'Motivos de la emergencia'}
                        data={report?.byMotives ?? {}}
                    />
                </div>
                <div style={{ marginTop: 20 }}>
                    <LineChart
                        title={'Solicitudes durante el mes'}
                        data={report?.byDate ?? {}}
                    />
                </div>
            </div>
        </main>
    );
};

export default PetitionsReportView;
