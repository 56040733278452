import { Line } from 'react-chartjs-2';

import SadFaceIcon from '../../../icons/sad-face.icon';

import styles from './line-chart.module.scss';

interface Props {
    title: string;
    data: Record<string, number>;
}

const LineChart = ({ title, data }: Props) => {
    if (Object.keys(data).length === 0) {
        return (
            <div className={styles.container}>
                <h5>{title}</h5>
                <div
                    style={{
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 8,
                    }}
                >
                    <SadFaceIcon color={'#5c636b'} />
                    <h6>No hay datos generar un gráfico</h6>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h5>{title}</h5>
            <div
                style={{
                    height: 300,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                <Line
                    data={{
                        labels: Object.keys(data),
                        datasets: [
                            {
                                data: Object.values(data),
                                borderColor: '#aeee9c',
                                backgroundColor: '#66b450',
                                cubicInterpolationMode: 'monotone',
                            },
                        ],
                    }}
                />
            </div>
        </div>
    );
};

export default LineChart;
