import { ReactElement } from 'react';
import classNames from 'classnames';

import TableRow from '../table-row/table-row.component';

import styles from './table.module.scss';

interface Props {
    mode?: string;
    header: string[];
    data: any[];
    currentPage: number;
    totalPages: number;
    onItemSelected?: (id: number) => void;
    onPageChange: (page: number) => void;
}

const Table = (props: Props) => {
    const showInvoiceDetails = (id: number) => {
        props.onItemSelected?.(id);
    };

    const buildRows = (data: any[]): ReactElement[] => {
        return data.map((item, idx) => {
            return (
                <TableRow
                    key={idx}
                    id={item.id}
                    data={item.data}
                    onClick={showInvoiceDetails}
                />
            );
        });
    };

    const buildPagination = (currentPage: number, pagesCount: number) => {
        const pageButtons = [
            <span key={1}>
                Página {currentPage} de {pagesCount}
            </span>,
        ];
        pageButtons.push(
            <button
                key={2}
                className={classNames({
                    [styles.pageButton]: true,
                    [styles.inactive]: currentPage <= 1,
                })}
                onClick={() => props.onPageChange(props.currentPage - 1)}
            >
                {'<'}
            </button>
        );
        pageButtons.push(
            <button
                key={3}
                className={classNames({
                    [styles.pageButton]: true,
                    [styles.inactive]: currentPage >= pagesCount,
                })}
                onClick={() => props.onPageChange(props.currentPage + 1)}
            >
                {'>'}
            </button>
        );

        return pageButtons;
    };

    return (
        <div className={styles.container}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {props.header.map((title, idx) => (
                            <th key={idx}>{title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>{buildRows(props.data)}</tbody>
            </table>
            <footer>
                {buildPagination(props.currentPage, props.totalPages)}
            </footer>
        </div>
    );
};

export default Table;
