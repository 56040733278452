import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import Loader from '../loader/loader';

import styles from './place-autocomplete.module.scss';

interface Props {
    placeholder?: string;
    places: { address: string; placeId: string }[];
    onSelect: (id: string) => void;
    onSearch: (address: string) => void;
    onClear?: () => void;
}

const PlaceAutocomplete = ({
    placeholder,
    places,
    onSelect,
    onClear,
    onSearch,
}: Props) => {
    let searchTimeout: NodeJS.Timeout;

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [activeSearch, setActiveSearch] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        places.length > 0 && setLoading(false);
    }, [places]);

    const clearResults = () => {
        setLoading(false);
        setActiveSearch(false);
    };

    const search = (value: string) => {
        if (searchTimeout) clearTimeout(searchTimeout);
        if (!value || value.length < 3) {
            onClear?.();
            return clearResults();
        }
        setLoading(true);

        searchTimeout = setTimeout(() => {
            setActiveSearch(true);
            onSearch(value);
        }, 800);
    };

    const select = (place: any) => {
        setActiveSearch(false);
        inputRef.current!.value = place.address;
        onSelect(place.placeId);
    };

    return (
        <div className={styles.container}>
            {loading && (
                <Loader size={20} className={styles.loader} color={'#999'} />
            )}
            <input
                ref={inputRef}
                placeholder={placeholder ?? 'Busca tu lugar aquí'}
                className={classNames({
                    [styles.input]: true,
                    [styles.activeSearch]: activeSearch,
                })}
                onChange={(ev) => search(ev.target.value.trim())}
            />
            {places.length > 0 && (
                <ul className={styles.list}>
                    {places.map((place) => (
                        <li key={place.placeId} onClick={() => select(place)}>
                            {place.address}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default PlaceAutocomplete;
