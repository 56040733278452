import classNames from 'classnames';

import ArrowLeftIcon from '../../icons/arrow-left.icon';
import { useNavigation } from '../../hooks/navigation.hook';
import { ThemeType } from '../../types/theme.type';

import styles from './go-back.module.scss';

interface Props {
    theme: ThemeType;
}

const GoBack = ({ theme }: Props) => {
    const { goBack } = useNavigation();

    return (
        <div
            className={classNames([
                styles.goBack,
                theme === 'light' ? styles.light : styles.dark,
            ])}
            onClick={goBack}
        >
            <ArrowLeftIcon
                size={16}
                color={theme === 'light' ? '#5c636b' : 'white'}
            />
            <span>Volver</span>
        </div>
    );
};

export default GoBack;
