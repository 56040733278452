class Logger {
    static info(message: string, ...optionalParams: any) {
        const date = new Date();
        return console.log(
            `[${date.toLocaleDateString()} - ${date.toLocaleTimeString()}] - ${message}`,
            ...optionalParams
        );
    }

    static warn(message: string, ...optionalParams: any) {
        const date = new Date();
        return console.warn(
            `[${date.toLocaleDateString()} - ${date.toLocaleTimeString()}] - ${message}`,
            ...optionalParams
        );
    }

    static error(message: string, ...optionalParams: any) {
        const date = new Date();
        return console.error(
            `[${date.toLocaleDateString()} - ${date.toLocaleTimeString()}] - [!] ${message}`,
            ...optionalParams
        );
    }

    static dev(message: string, ...optionalParams: any) {
        if (process.env.REACT_APP_NODE_ENV === 'production') return;
        const date = new Date();
        return console.info(
            `[${date.toLocaleDateString()} - ${date.toLocaleTimeString()}] - [!] ${message}`,
            ...optionalParams
        );
    }
}

export default Logger;
