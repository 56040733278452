import { PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './button.module.scss';

type ButtonVariants = 'primary' | 'secondary';

type Props = PropsWithChildren & {
    variant?: ButtonVariants;
    icon?: ReactNode;
    iconPosition?: 'right' | 'left';
    disabled?: boolean;
    borderRadius?: number;
    width?: number | string;
    onPress: () => void;
};

const Button = ({
    children: text,
    icon,
    iconPosition,
    variant = 'primary',
    disabled,
    borderRadius = 6,
    width,
    onPress,
}: Props) => {
    const renderChildren = (text: ReactNode, icon?: ReactNode): ReactNode[] => {
        const children = [icon, <span key={1}>{text}</span>];
        return iconPosition === 'right' ? children.reverse() : children;
    };

    return (
        <button
            className={classNames({
                [styles.baseButton]: true,
                [styles[variant]]: true,
            })}
            style={{
                borderRadius: borderRadius,
                width: width ?? 'fit-content',
            }}
            disabled={disabled}
            onClick={(ev) => {
                ev.preventDefault();
                onPress();
            }}
        >
            {renderChildren(text, icon)}
        </button>
    );
};

export default Button;
