import Ably, { Types } from 'ably/callbacks';

import Logger from '../utils/logger.util';

const socketClient = new Ably.Realtime.Callbacks(
    process.env.REACT_APP_ABLY_TOKEN as string
);

socketClient.connection.on('connected', () => Logger.dev('Socket connected!'));

const subscribe = (
    target: { channel: string; event: string },
    cb: (message: Types.Message) => void
) => {
    const channel = socketClient.channels.get(target.channel);
    channel.subscribe(target.event, cb);
};

const publishMessage = (message: {
    channel: string;
    event: string;
    data: any;
}) => {
    const channel = socketClient.channels.get(message.channel);
    channel.publish(message.event, message.data);
};

export const Realtime = {
    publishMessage,
    subscribe,
};
