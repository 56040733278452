import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { array, number, object, string } from 'yup';

import GoBack from '../../components/go-back/go-back';
import { User } from '../../types/user.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response-codes.constant';
import { useAuthStore, usePetitionStore, useSettingsStore } from '../../store';
import Button from '../../components/button/button';
import { petitionSdk } from '../../services/petition.service';
import Logger from '../../utils/logger.util';
import { PetitionMotive } from '../../types/petition-motive.type';
import { useNavigation } from '../../hooks/navigation.hook';

import styles from './create-petition.module.scss';
import UserSelection from './user-selection/user-selection';
import ReasonSelection from './reason-selection/reason-selection';
import LocationSelection from './location-selection/location-selection';

const CreatePetitionView = () => {
    const { navigate } = useNavigation();
    const { accessToken } = useAuthStore();
    const { theme } = useSettingsStore();
    const { motives } = usePetitionStore();
    const [userList, setUserList] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User>();
    const [selectedMotives, setSelectedMotives] = useState<PetitionMotive[]>(
        []
    );
    const [location, setLocation] = useState({
        lat: 0,
        lng: 0,
        address: '',
    });
    const [buttonConfig, setButtonConfig] = useState({
        text: 'Crear solicitud',
        disabled: true,
    });

    useEffect(() => {
        checkForm();
    }, [selectedUser, location, selectedMotives]);

    const searchUser = async (value: string) => {
        const response = await userSdk.searchUser(value);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return Logger.error('error fetching users:', response);
        }
        setUserList(response.data);
    };

    const getUserDetails = async (id: number) => {
        const response = await userSdk.getUserById(id);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return Logger.error('error fetching user:', response);
        }
        setSelectedUser(response.data);
    };

    const checkForm = async () => {
        const schema = object({
            userId: number().min(1).required(),
            location: object({
                lat: number().required(),
                lng: number().required(),
                address: string().min(3).required(),
            }),
            motives: array().of(string()).optional(),
        });
        const valid = await schema.isValid({
            userId: selectedUser?.id,
            location: location,
            motives: selectedMotives.map((motive) => motive.id),
        });
        setButtonConfig({
            text: 'Crear solicitud',
            disabled: !valid,
        });
    };

    const createPetition = async () => {
        setButtonConfig({
            text: 'Creando...',
            disabled: true,
        });
        const payload = {
            userId: selectedUser!.id,
            location: location,
            motives: selectedMotives.map((motive) => motive.id),
        };

        const response = await petitionSdk.createPetition(
            payload,
            accessToken!
        );
        if (response.code !== ResponseCodes.CREATED) {
            Logger.error('Error creating petition:', response);
            return setButtonConfig({
                text: 'Crear solicitud',
                disabled: false,
            });
        }
        return navigate('/', { replace: true });
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div
                className={'viewContent'}
                style={{ paddingLeft: 40, paddingRight: 40 }}
            >
                <header>
                    <GoBack theme={theme.current} />
                    <h1 className={'view-title'}>
                        Crear solicitud de emergencia
                    </h1>
                </header>
                <form className={styles.form}>
                    <UserSelection
                        selectedUser={selectedUser}
                        userList={userList}
                        onClear={() => setSelectedUser(undefined)}
                        onSelect={getUserDetails}
                        onSearch={searchUser}
                        onCreated={setSelectedUser}
                    />
                    <LocationSelection onSelect={setLocation} />
                    <ReasonSelection
                        motives={motives}
                        onSelection={setSelectedMotives}
                    />
                    <div style={{ marginTop: 20 }}>
                        <Button
                            onPress={createPetition}
                            disabled={buttonConfig.disabled}
                        >
                            {buttonConfig.text}
                        </Button>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default CreatePetitionView;
