import classNames from 'classnames';
import {
    differenceInHours,
    differenceInMinutes,
    differenceInSeconds,
} from 'date-fns';

import { PetitionStatus } from '../../constants/petition-status.constant';

import styles from './petition-status-list.module.scss';

interface Props {
    statusList?: { status: number; date: string }[];
}

const PetitionStatusList = (props: Props) => {
    const isLastStatus = (idx: number): boolean => {
        return Object.keys(PetitionStatus).length - 1 === idx;
    };

    const getStatusTransition = (idx: number): string => {
        const current = props.statusList![idx];
        const next = props.statusList![idx - 1];

        if (!next) return '';

        let diff = differenceInSeconds(
            new Date(next.date),
            new Date(current.date)
        );
        if (diff < 59) return `${diff} seg`;

        diff = differenceInMinutes(new Date(next.date), new Date(current.date));
        if (diff < 59) return `${diff} min`;

        return `${differenceInHours(
            new Date(next.date),
            new Date(current.date)
        )} horas`;
    };

    return (
        <ul className={styles.statusPath}>
            {Object.keys(PetitionStatus).map((status, idx) => {
                const petitionStatusIdx = props.statusList?.findIndex(
                    (item) => item.status === Number(status)
                );

                return (
                    <li key={status} className={styles.step}>
                        <div className={styles.draws}>
                            <div
                                className={classNames({
                                    [styles.circle]: true,
                                    [styles.active]: petitionStatusIdx !== -1,
                                })}
                            />
                            {!isLastStatus(idx) && (
                                <div className={styles.line} />
                            )}
                        </div>
                        <div className={styles.info}>
                            <h5 className={styles.name}>
                                {PetitionStatus[Number(status)]}
                            </h5>
                            {petitionStatusIdx !== -1 && (
                                <span className={styles.transitionTime}>
                                    {getStatusTransition(petitionStatusIdx!)}
                                </span>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default PetitionStatusList;
