import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

import { User } from '../../types/user.type';
import { Petition } from '../../types/petition.type';
import { userSdk } from '../../services/user.service';
import ResponseCodes from '../../constants/response-codes.constant';
import PetitionStatusList from '../../components/petition-status-list/petition-status-list';
import {
    useOperatorStore,
    usePetitionStore,
    useSettingsStore,
} from '../../store';
import GoBack from '../../components/go-back/go-back';
import { Operator } from '../../types/operator.type';
import Divider from '../../components/divider/divider';
import { PetitionComment } from '../../types/petition-comment.type';

import styles from './petition-details.module.scss';

const PetitionDetailsView = () => {
    const { ['id']: petitionId } = useParams();
    const { theme } = useSettingsStore();
    const petitionState = usePetitionStore();
    const { operators } = useOperatorStore();
    const [petition, setPetition] = useState<Petition | null>(null);
    const [user, setUser] = useState<User | undefined>(undefined);
    const [petitionOperators, setPetitionOperators] = useState<Operator[]>([]);

    useEffect(() => {
        fetchPetition();
    }, []);

    useEffect(() => {
        if (!petition) return;

        getUserInfo(petition.user!.id);
        getOperatorsDetails(petition.operators!);
    }, [petition]);

    const fetchPetition = async () => {
        setPetition(await petitionState.fetchById(Number(petitionId)));
    };

    const getUserInfo = async (userId: number) => {
        const response = await userSdk.getUserById(userId);
        if (response.code !== ResponseCodes.PROCESS_OK) {
            console.error('[!] Error getting user info:', response);
        }
        setUser(response.data);
    };

    const getOperatorsDetails = (operatorIds: number[]) => {
        const selectedOperators = operators.filter((operator) =>
            operatorIds.includes(operator.id)
        );
        setPetitionOperators(selectedOperators);
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'} style={{ padding: '20px 40px' }}>
                <header>
                    <GoBack theme={theme.current} />
                    <h1 className={'view-title'} style={{ fontSize: 24 }}>
                        Solicitud Nº {petition?.id}
                    </h1>
                </header>
                <div>
                    <h4>Dirección</h4>
                    <span>
                        {petition?.referencePoint ??
                            petition?.googleReferencePoint}
                    </span>
                </div>
                <div>
                    <h4>Fecha</h4>
                    <span>
                        {petition &&
                            format(
                                new Date(petition?.createdAt),
                                "MMMM dd, yyyy 'a las' hh:mm:ss aaaa",
                                { locale: es }
                            )}
                    </span>
                </div>
                <section className={styles.content}>
                    {petition?.statusHistory && (
                        <PetitionStatusList
                            statusList={petition.statusHistory}
                        />
                    )}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '300px',
                        }}
                    >
                        {user?.id && (
                            <div
                                className={classNames({
                                    [styles.infoSection]: true,
                                    [styles.userInfo]: true,
                                })}
                            >
                                <h5>Solicitado por:</h5>
                                <span>
                                    Nombre: {user.firstName} {user.lastName}
                                </span>
                                <span>
                                    Cédula: {user.documentType}-{user.document}
                                </span>
                            </div>
                        )}
                        <div
                            className={classNames({
                                [styles.infoSection]: true,
                                [styles.cmcInfo]: true,
                            })}
                        >
                            <h5>Atendida por:</h5>
                            <span>{petition?.cmc?.fullName ?? 'N/A'}</span>
                        </div>
                        <div
                            className={classNames({
                                [styles.infoSection]: true,
                                [styles.cmcInfo]: true,
                            })}
                        >
                            <h5>Operadores:</h5>
                            {petitionOperators.length > 0 ? (
                                <ul>
                                    {petitionOperators.map((operator) => (
                                        <li key={operator.id}>
                                            {operator.firstName}{' '}
                                            {operator.lastName}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <span>Ningún operador fue despachado.</span>
                            )}
                        </div>
                    </div>
                </section>
                <Divider />
                <div>
                    <h4>Comentarios:</h4>
                    {petition?.comments?.length === 0 ? (
                        <span>No hay comentarios.</span>
                    ) : (
                        <ul className={styles.commentList}>
                            {petition
                                ?.comments!.reverse()
                                .map((comment, idx) => (
                                    <Comment comment={comment} key={idx} />
                                ))}
                        </ul>
                    )}
                </div>
            </div>
        </main>
    );
};

const Comment = ({ comment }: { comment: PetitionComment }) => {
    return (
        <li className={styles.comment}>
            <span className={styles.content}>{comment.content}</span>
            <span className={styles.commentMetadata}>
                <b>Autor:</b> {comment.author?.firstName}{' '}
                {comment.author?.lastName}
            </span>
            <span>
                <b>Fecha:</b>{' '}
                {format(
                    new Date(comment.createdAt),
                    'MMMM dd, yyyy hh:mm:ss aaaa',
                    { locale: es }
                )}
            </span>
        </li>
    );
};

export default PetitionDetailsView;
