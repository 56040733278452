import { JSX, useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';
import classNames from 'classnames';

import ArrowBackIcon from '../../icons/arrow-back.icon';
import Divider from '../divider/divider';
import { Petition } from '../../types/petition.type';
import { User } from '../../types/user.type';
import SelectOperator from '../select-operators/select-operator';
import {
    initialState,
    mapStatusToStep,
    petitionStateMachine,
} from '../../state-machine/petition.sm';
import { Vehicle } from '../../types/vehicle.type';
import {
    useAuthStore,
    useOperatorStore,
    usePetitionStore,
    useUserStore,
} from '../../store';
import { Operator } from '../../types/operator.type';
import { petitionSdk } from '../../services/petition.service';
import { useDebounce } from '../../hooks/debounce.hook';
import Checkbox from '../checkbox/checkbox';
import SubscriptionStatus from '../../constants/subscription.status';
import CopyLink from '../copy-link/copy-link';

import styles from './petition-card.module.scss';

interface Props {
    petition?: Petition;
    shown: boolean;
    onDismiss: () => void;
    onDiscardPetition: (id: number) => void;
    onOpenMetadata: () => void;
    onCancelPetition: (id: number) => void;
}

let interval: number;

const PetitionCard = (props: Props) => {
    const { accessToken } = useAuthStore();
    const petitionStore = usePetitionStore();
    const { getUserVehicles } = useUserStore();
    const { operators } = useOperatorStore();
    const [durationTime, setDurationTime] = useState('00:00:00');
    const [petitionState, setPetitionState] = useState(
        petitionStateMachine[initialState]
    );
    const [buttonLoading, setButtonLoading] = useState(false);
    const [discardButton, setDiscardButton] = useState(false);
    const [vehicles, setVehicles] = useState<Vehicle[]>([]);
    const [operatorsList, setOperatorsList] = useState<
        (Operator & { selected: boolean })[]
    >([]);
    const [plan, setPlan] = useState({
        name: 'Suscripción',
        active: false,
    });
    const [updatedOps, setUpdatedOps] = useState<any[]>([]);
    const debouncedUpdateOps = useDebounce(updatedOps, 5000);

    useEffect(() => {
        props.petition && props.shown && updateDuration();

        // Remove timer (of passed seconds) when component is "unmounted"
        return () => clearInterval(interval);
    }, [props.petition, props.shown]);

    useEffect(() => {
        if (!props.petition) return;

        if (!props.petition.isCancelled) {
            setPetitionState(
                petitionStateMachine[mapStatusToStep[props.petition.status]]
            );
        } else {
            setDiscardButton(true);
        }
        fetchUserVehicles();
        showUserPlan(props.petition.user);
    }, [props.petition]);

    useEffect(() => {
        setDiscardButton(petitionState.nextStatus === -1);
    }, [petitionState]);

    useEffect(() => {
        operators.length > 0 &&
            setOperatorsList(
                operators.map((operator) => ({ ...operator, selected: false }))
            );
    }, [operators]);

    useEffect(() => {
        if (debouncedUpdateOps.length === 0) return;
        petitionSdk.addOperators(
            {
                id: props.petition!.id,
                operators: debouncedUpdateOps.map((operator) => operator.id),
            },
            accessToken!
        );
    }, [debouncedUpdateOps]);

    const fetchUserVehicles = async () => {
        setVehicles(await getUserVehicles(props.petition!.user!.id));
    };

    const updateDuration = () => {
        interval = window.setInterval(() => {
            const now = new Date().getTime();
            const petitionCreation = new Date(
                props.petition!.createdAt
            ).getTime();
            const duration = intervalToDuration({
                start: petitionCreation,
                end: now,
            });
            const time = {
                hours: `0${duration.hours}`.slice(-2),
                min: `0${duration.minutes}`.slice(-2),
                sec: `0${duration.seconds}`.slice(-2),
            };
            setDurationTime(`${time.hours}:${time.min}:${time.sec}`);
        }, 1000);
    };

    const onDismiss = () => {
        clearInterval(interval);
        props.onDismiss();
    };

    const updatePetitionState = async () => {
        if (!petitionState.transition) return;
        petitionStore.stopAlert();
        setButtonLoading(true);
        const nextState = await petitionState.transition(props.petition!.id);
        setPetitionState(nextState);
        setButtonLoading(false);
    };

    const discardPetition = () => {
        props.onDiscardPetition(props.petition!.id);
    };

    const renderVehicles = (
        vehicles: Vehicle[]
    ): JSX.Element | JSX.Element[] => {
        if (vehicles.length === 0) {
            return <span>El usuario no posee vehículos registrados</span>;
        }
        return vehicles.map((vehicle) => {
            const name = `${vehicle.brand} ${vehicle.model} - ${
                vehicle.color
            } ${vehicle.plate || '(Sin placa)'}`;

            return (
                <Checkbox id={vehicle.id} key={vehicle.id}>
                    {name}
                </Checkbox>
            );
        });
    };

    const toggleOperator = (id: number) => {
        setOperatorsList((operators) =>
            operators.map((operator) => {
                if (operator.id === id) {
                    operator.selected = !operator.selected;
                }
                return operator;
            })
        );
        const selectedOnes = operatorsList.filter(
            (operator) => operator.selected
        );
        const toggleOp = operatorsList.find((operator) => operator.id === id);
        if (toggleOp && !toggleOp.selected) {
            selectedOnes.push(toggleOp);
        }
        setUpdatedOps(selectedOnes);
    };

    const showUserPlan = (user?: User) => {
        if (!user) return setPlan({ name: 'Suscripción', active: false });
        const active = user.subscriptions!.find(
            (sub) => sub.status === SubscriptionStatus.ACTIVE
        );
        if (!active) return setPlan({ name: 'Suscripción', active: false });
        return setPlan({
            name: `${active.service.name}`,
            active: true,
        });
    };

    const getPetitionLocationLink = (petition?: Petition): string => {
        return `https://maps.google.com/?q=${petition?.lat},${petition?.lng}`;
    };

    return (
        <div
            className={classNames({
                [styles.card]: true,
                [styles.shown]: props.shown,
            })}
        >
            <div className={styles.cardContainer}>
                <div className={'row space-between'}>
                    <div className={styles.backButton} onClick={onDismiss}>
                        <ArrowBackIcon size={18} color={'#666'} />
                    </div>
                    <span>#{props.petition?.id}</span>
                </div>
                <div className={'row column'}>
                    {props.petition?.isCancelled && (
                        <span className={styles.cancelledText}>
                            Emergencia cancelada
                        </span>
                    )}
                    {props.petition?.isFinished && (
                        <span className={styles.finishedText}>
                            Emergencia finalizada
                        </span>
                    )}
                    {discardButton ? (
                        <button
                            className={styles.discardButton}
                            onClick={discardPetition}
                        >
                            Marcar como cerrada
                        </button>
                    ) : (
                        <button
                            className={styles.changeStatusButton}
                            onClick={updatePetitionState}
                            disabled={buttonLoading}
                        >
                            {buttonLoading ? 'Cargando...' : petitionState.text}
                        </button>
                    )}
                </div>
                <div className={'row space-between'}>
                    <div className={'column'}>
                        <span
                            className={classNames({
                                [styles.planName]: true,
                                [styles.active]: plan.active,
                            })}
                        >
                            {plan.name}
                        </span>
                        <span className={styles.tip}>Tiempo en curso:</span>
                    </div>
                    <div
                        className={'column'}
                        style={{ alignItems: 'flex-end' }}
                    >
                        <span
                            className={classNames({
                                [styles.planName]: true,
                                [styles.active]: plan.active,
                            })}
                        >
                            {plan.active ? 'Activa' : 'Inactiva'}
                        </span>
                        <span className={styles.tip}>{durationTime}</span>
                    </div>
                </div>
                <Divider />
                <div className={'column'} style={{ gap: 6 }}>
                    <span className={styles.subtitle}>Dirección</span>
                    <span>{props.petition?.googleReferencePoint}</span>
                    <CopyLink link={getPetitionLocationLink(props.petition)}>
                        Copiar dirección
                    </CopyLink>
                </div>
                <Divider />
                <div className={'column'} style={{ gap: 6 }}>
                    <span className={styles.subtitle}>
                        Detalles del cliente
                    </span>
                    <span>
                        Nombre: {props.petition?.user?.firstName}{' '}
                        {props.petition?.user?.lastName}
                    </span>
                    <span>Teléfono: {props.petition?.user?.phoneNumber}</span>
                    <span>
                        Cédula: {props.petition?.user?.documentType}-
                        {props.petition?.user?.document}
                    </span>
                    {props.petition?.user?.company && (
                        <span>
                            Compañía:{' '}
                            <b>{props.petition?.user.company.displayName}</b>
                        </span>
                    )}
                </div>
                <Divider />
                <div className={'column'} style={{ gap: 10 }}>
                    <span className={styles.subtitle}>Operadores</span>
                    <SelectOperator
                        operators={operatorsList}
                        onSelect={toggleOperator}
                    />
                </div>
                <Divider />
                <div className={'column'} style={{ gap: 10 }}>
                    <span className={styles.subtitle}>
                        Vehículos del usuario:
                    </span>
                    {renderVehicles(vehicles)}
                </div>
                <Divider />
                <div className={'column'} style={{ gap: 10 }}>
                    <span className={styles.subtitle}>Datos adicionales:</span>
                    <span
                        className={styles.link}
                        onClick={props.onOpenMetadata}
                    >
                        Mostrar los datos adicionales
                    </span>
                </div>
                {!discardButton && (
                    <footer>
                        <Divider />
                        <div className={'column'} style={{ gap: 10 }}>
                            <button
                                className={styles.cancelPetitionButton}
                                onClick={() =>
                                    props.onCancelPetition(props.petition!.id)
                                }
                            >
                                Cancelar solicitud
                            </button>
                        </div>
                    </footer>
                )}
            </div>
        </div>
    );
};

export default PetitionCard;
