import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useSettingsStore, useUserStore } from '../../store';
import Table from '../../components/table/table.component';
import { mapUserHasSubscription } from '../../utils/user-subscription.util';
import { useNavigation } from '../../hooks/navigation.hook';
import SearchInput from '../../components/search-input/search-input';

import styles from './users.module.scss';

const UsersView = () => {
    const { navigate } = useNavigation();
    const { theme } = useSettingsStore();
    const userStore = useUserStore();
    const [rows, setRows] = useState<any[]>([]);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalPages: 1,
        count: 10,
    });
    const [filter, setFilter] = useState('');

    useEffect(() => {
        const params: {
            count: number;
            page: number;
            search?: string;
        } = {
            count: pagination.count,
            page: pagination.currentPage,
        };
        if (filter) {
            params.search = filter;
        } else {
            params.count = 10;
        }
        userStore.fetchAll(params);
    }, [pagination.currentPage, filter]);

    useEffect(() => {
        mapUsers();
    }, [userStore.list]);

    const updateFilter = (filter: string) => {
        setFilter(filter.trim());
        setPagination((prev) => ({ ...prev, currentPage: 1, count: 10 }));
    };

    const mapUsers = () => {
        setPagination((prev) => ({
            ...prev,
            currentPage: Number(userStore.list.page) ?? 1,
            totalPages:
                userStore.list.count === 0
                    ? 1
                    : Math.ceil(userStore.list.total / pagination.count),
        }));
        const userRows = userStore.list.result.map((user) => {
            return {
                id: user.id,
                data: [
                    { type: 'number', value: user.id },
                    {
                        type: 'string',
                        value: `${user.firstName} ${user.lastName ?? ''}`,
                    },
                    {
                        type: 'string',
                        value: `${user.phoneNumber ?? '-'}`,
                    },
                    {
                        type: 'string',
                        value: `${user.documentType ?? '?'}-${
                            user.document ?? '?'
                        }`,
                    },
                    {
                        type: 'status',
                        value: mapUserHasSubscription(user),
                    },
                    {
                        type: 'date',
                        value: new Date(user.createdAt),
                    },
                ],
            };
        });
        setRows(userRows);
    };

    const onPageChange = (page: number) => {
        if (page < 1) return;
        setPagination((prev) => ({ ...prev, currentPage: page }));
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Lista de usuarios</h1>
                <div className={'flex align-center'} style={{ gap: 10 }}>
                    <SearchInput
                        onSearch={updateFilter}
                        className={styles.searchInput}
                    />
                </div>
                <Table
                    header={[
                        'ID',
                        'Nombre',
                        'Teléfono',
                        'Cédula',
                        'Suscripción',
                        'Fecha creación',
                    ]}
                    data={rows}
                    currentPage={pagination.currentPage}
                    totalPages={pagination.totalPages}
                    onPageChange={onPageChange}
                    onItemSelected={(id) => navigate(`/users/${id}`)}
                />
            </div>
        </main>
    );
};

export default UsersView;
