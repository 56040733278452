import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { number, object, string } from 'yup';

import { useNavigation } from '../../hooks/navigation.hook';
import { useAuthStore, useOperatorStore, useSettingsStore } from '../../store';
import { useIsCoordinator } from '../../hooks/is-coordinator.hook';
import Button from '../../components/button/button';

import styles from './create-operator.module.scss';

const formSchema = object().shape({
    firstName: string().min(3).required(),
    lastName: string().min(3).required(),
    documentType: string().oneOf(['V', 'E', 'P']).required(),
    document: string().min(6).max(10).required(),
    code: string().min(1).optional(),
    email: string().email().required(),
    password: string().min(8).required(),
    roleId: number().min(1).required(),
});

const CreateOperatorView = () => {
    const { navigate } = useNavigation();
    const { accessToken, profile } = useAuthStore();
    const { theme } = useSettingsStore();
    const { roles, getRoles, createOperator } = useOperatorStore();
    useIsCoordinator(profile!);

    const [form, setForm] = useState({
        firstName: '',
        lastName: '',
        code: undefined,
        email: '',
        password: '',
        documentType: '',
        document: '',
        roleId: 0,
    });
    const [createButton, setCreateButton] = useState({
        text: 'Crear operador',
        disabled: true,
    });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        accessToken && getRoles(accessToken);
    }, [accessToken]);

    useEffect(() => {
        validateForm(form);
    }, [form]);

    const validateForm = (form: any) => {
        const isValid = formSchema.isValidSync(form);
        if (!isValid) {
            return setCreateButton({ text: 'Crear operador', disabled: true });
        }
        setCreateButton((prev) => ({ ...prev, disabled: false }));
    };

    const updateField = (key: string, value: string | number) => {
        setForm((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const onCreate = async () => {
        setErrorMessage('');
        setCreateButton({
            text: 'Cargando...',
            disabled: true,
        });
        const { error } = await createOperator(
            {
                firstName: form.firstName,
                lastName: form.lastName,
                document: { type: form.documentType, number: form.document },
                password: form.password,
                email: form.email,
                code: form.code,
                roleId: Number(form.roleId),
            },
            accessToken!
        );
        if (error) {
            setCreateButton({
                text: 'Crear operador',
                disabled: false,
            });
            return setErrorMessage(error);
        }
        return navigate('/operators');
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Crear nuevo operador</h1>

                <form className={styles.form}>
                    <div className={styles.row}>
                        <label>Nombres:</label>
                        <input
                            type={'text'}
                            autoCapitalize={'on'}
                            onChange={(ev) =>
                                updateField('firstName', ev.target.value.trim())
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <label>Apellidos:</label>
                        <input
                            type={'text'}
                            autoCapitalize={'on'}
                            onChange={(ev) =>
                                updateField('lastName', ev.target.value.trim())
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <label>Documento de identidad:</label>
                        <div className={'flex'} style={{ gap: 8 }}>
                            <select
                                defaultValue={'?'}
                                onChange={(ev) =>
                                    updateField('documentType', ev.target.value)
                                }
                            >
                                <option value={'?'} disabled>
                                    ?
                                </option>
                                <option value={'V'}>V</option>
                                <option value={'E'}>E</option>
                                <option value={'P'}>P</option>
                            </select>
                            <input
                                type={'Número de documento de identidad'}
                                onChange={(ev) =>
                                    updateField(
                                        'document',
                                        ev.target.value.trim()
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={styles.row}>
                        <label>Código:</label>
                        <input
                            type={'text'}
                            placeholder={'(Es opcional)'}
                            onChange={(ev) =>
                                updateField('code', ev.target.value.trim())
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <label>Correo electrónico:</label>
                        <input
                            type={'email'}
                            onChange={(ev) =>
                                updateField('email', ev.target.value.trim())
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <label>Contraseña:</label>
                        <input
                            type={'password'}
                            placeholder={'Mínimo 8 caracteres'}
                            onChange={(ev) =>
                                updateField('password', ev.target.value.trim())
                            }
                        />
                    </div>
                    <div className={styles.row}>
                        <label>Rol en la compañía:</label>
                        <select
                            style={{ width: '100%' }}
                            defaultValue={0}
                            onChange={(ev) =>
                                updateField('roleId', ev.target.value)
                            }
                        >
                            <option disabled value={0}>
                                Escoge un rol
                            </option>
                            {roles.map((role) => (
                                <option key={role.id} value={role.id}>
                                    {role.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    {errorMessage && (
                        <span className={styles.errorMessage}>
                            {errorMessage}
                        </span>
                    )}
                    <div style={{ marginTop: 20 }}>
                        <Button
                            onPress={onCreate}
                            disabled={createButton.disabled}
                        >
                            {createButton.text}
                        </Button>
                    </div>
                </form>
            </div>
        </main>
    );
};

export default CreateOperatorView;
