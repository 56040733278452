interface Props {
    color?: string;
    size?: number;
}

const DateIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g id="date_range_24px">
                <path
                    id="icon/action/date_range_24px"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 22C20.1 22 21 21.1 21 20V6C21 4.89999 20.1 4 19 4H18V2H16V4H8V2H6V4H5C3.89001 4 3.01001 4.89999 3.01001 6L3 20C3 21.1 3.89001 22 5 22H19ZM9 13V11H7V13H9ZM5 8H19V6H5V8ZM19 10V20H5V10H19ZM17 13V11H15V13H17ZM13 13H11V11H13V13Z"
                    fill={props.color || '#565454'}
                />
            </g>
        </svg>
    );
};

export default DateIcon;
