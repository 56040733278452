import { useAuthStore } from '../../store';

import styles from './profile.module.scss';

const ProfileView = () => {
    const { profile } = useAuthStore();

    return (
        <main className={'view'}>
            <div className={'viewContent'}>
                <h1 className={'view-title'}>Mi perfil</h1>
            </div>
            <dl className={styles.descriptionTable}>
                <div className={styles.row}>
                    <dt>Nombre completo</dt>
                    <dd>
                        {profile?.firstName} {profile?.lastName}
                    </dd>
                </div>
                <div className={styles.row}>
                    <dt>Correo</dt>
                    <dd>{profile?.email}</dd>
                </div>
                <div className={styles.row}>
                    <dt>Rol</dt>
                    <dd>{profile?.role?.displayName}</dd>
                </div>
            </dl>
        </main>
    );
};

export default ProfileView;
