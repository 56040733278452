import { useEffect, useRef, useState } from 'react';

import CloseIcon from '../../icons/close.icon';
import { PetitionMotive } from '../../types/petition-motive.type';
import { normalize } from '../../utils/string.util';

import styles from './tags-input.module.scss';

type Tag = {
    id: number;
    name: string;
};

interface Props {
    tags: Tag[];
    preSelectedTags: Tag[];
    onSelection: (tags: Tag[]) => void;
    height?: number;
    placeholder?: string;
}

const TagsInput = ({
    placeholder,
    tags,
    preSelectedTags,
    onSelection,
    height,
}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [defaultTags, setDefaultTags] = useState<Tag[]>([]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>(preSelectedTags);
    const [coincidences, setCoincidences] = useState<PetitionMotive[]>([]);
    const [listShown, setListShown] = useState(false);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setDefaultTags(tags);
    }, [tags]);

    useEffect(() => {
        onSelection(selectedTags);
    }, [selectedTags]);

    const onInput = (text: string) => {
        if (!text || text.length < 3) {
            setListShown(false);
            return setCoincidences([]);
        }

        const normalizedText = normalize(text);
        const found = defaultTags.filter((tag) => {
            const normalizedReason = normalize(tag.name);
            if (normalizedReason.includes(normalizedText)) {
                return tag;
            }
        });
        setCoincidences(found);
        setListShown(found.length > 0);
    };

    const addTag = (tag: Tag) => {
        inputRef.current!.value = '';
        setListShown(false);
        setFocused(false);

        // Removed from main motive list (avoid to show a previous selected motive)
        setDefaultTags((prev) => prev.filter((defTag) => defTag.id !== tag.id));
        // Added it to selected ones
        setSelectedTags((prev) => [...prev, tag]);
    };

    const removeTag = (tag: Tag) => {
        // Remove motive from selected ones
        setSelectedTags((prev) =>
            prev.filter((selTag) => selTag.id !== tag.id)
        );
        // Add again motive to base list to be selected
        setDefaultTags((prev) => [...prev, tag]);
    };

    const handleFocus = () => {
        setFocused(true);
        coincidences.length > 0 && setListShown(true);
    };

    const hideResults = () => {
        setFocused(false);
        setListShown(false);
    };

    return (
        <div className={styles.container}>
            {focused && <div className={styles.wall} onClick={hideResults} />}
            <input
                ref={inputRef}
                placeholder={placeholder ?? 'Escribe la razón'}
                style={{
                    height: height ?? 40,
                    borderBottomLeftRadius: listShown ? 0 : 4,
                    borderBottomRightRadius: listShown ? 0 : 4,
                    zIndex: focused ? 1 : 'initial',
                }}
                type={'text'}
                onChange={(ev) => onInput(ev.target.value.trim())}
                onFocus={handleFocus}
            />
            {selectedTags.length > 0 && (
                <div className={styles.pillsContainer}>
                    {selectedTags.map((tag, idx) => (
                        <div key={idx} className={styles.tagPill}>
                            <span>{tag.name}</span>
                            <CloseIcon
                                size={14}
                                className={styles.deletePill}
                                onClick={() => removeTag(tag)}
                            />
                        </div>
                    ))}
                </div>
            )}
            {listShown && (
                <ul className={styles.list} style={{ top: height ?? 40 }}>
                    {coincidences.map((tag, idx) => (
                        <li key={idx} onClick={() => addTag(tag)}>
                            {tag.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default TagsInput;
