import { object, string } from 'yup';
import { ChangeEvent, MouseEvent, useState } from 'react';

import Modal from '../../../components/modal/modal';
import Loader from '../../../components/loader/loader';
import { userSdk } from '../../../services/user.service';
import ResponseCodes from '../../../constants/response-codes.constant';
import { useAuthStore } from '../../../store';
import { User } from '../../../types/user.type';

import styles from './user-selection.module.scss';

const formSchema = object().shape({
    fullName: string()
        .matches(/^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/, {
            message: 'Debes incluir tanto el nombre como el apellido',
        })
        .required('El nombre es obligatorio'),
    nid: string()
        .min(5, 'DNI no puede tener menos de 5 caracteres')
        .max(20, 'DNI no puede tener más de 20 caracteres')
        .required('EL DNI es obligatorio'),
    phone: string()
        .min(7, 'Teléfono inválido')
        .max(12, 'Teléfono inválido')
        .required('El teléfono es obligatorio'),
    email: string()
        .email('Formato de email inválido')
        .required('El correo es obligatorio'),
});

type FormField = 'fullName' | 'nid' | 'phone' | 'email';

type FormState = 'idle' | 'loading';

interface ModalProps {
    shown: boolean;
    onDiscard: () => void;
    onUserCreation: (user: User) => void;
}

const CreateUserModal = ({ shown, onDiscard, onUserCreation }: ModalProps) => {
    const { accessToken } = useAuthStore();
    const [form, setForm] = useState({
        fullName: '',
        nid: '',
        phone: '',
        email: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [formState, setFormState] = useState<FormState>('idle');

    const onFormInput = (
        field: FormField,
        ev: ChangeEvent<HTMLInputElement>
    ) => {
        const value = ev.target.value.trim();
        setForm((prev) => ({ ...prev, [field]: value.trim() }));
    };

    const validateForm = () => {
        try {
            console.log(form);
            formSchema.validateSync(form);
            return true;
        } catch (err) {
            setFormState('idle');
            setErrorMessage((err as any).message);
            return false;
        }
    };

    const onCreation = async (ev: MouseEvent) => {
        ev.preventDefault();

        setFormState('loading');
        setErrorMessage('');
        const isValid = validateForm();
        if (!isValid) return;

        const response = await userSdk.createUser(form, {
            token: accessToken!,
        });
        setFormState('idle');

        if (response.code !== ResponseCodes.CREATED) {
            return setErrorMessage(response.message);
        }

        onDiscard();
        onUserCreation(response.data!);
    };

    return (
        <Modal shown={shown} onDiscard={onDiscard}>
            <div className={styles.modal}>
                <h4>Crear usuario</h4>
                <div className={styles.column}>
                    <label>Nombre completo</label>
                    <input
                        type={'text'}
                        onChange={(ev) => onFormInput('fullName', ev)}
                    />
                </div>
                <div className={styles.row}>
                    <div className={styles.column}>
                        <label>Cédula</label>
                        <input
                            type={'text'}
                            onChange={(ev) => onFormInput('nid', ev)}
                        />
                    </div>
                    <div className={styles.column}>
                        <label>Teléfono</label>
                        <input
                            type={'tel'}
                            onChange={(ev) => onFormInput('phone', ev)}
                        />
                    </div>
                </div>
                <div className={styles.column}>
                    <label>Correo</label>
                    <input
                        type={'email'}
                        onChange={(ev) => onFormInput('email', ev)}
                    />
                </div>
                {errorMessage && (
                    <span className={styles.errorMessage}>{errorMessage}</span>
                )}
                <div className={styles.actions}>
                    <button
                        className={styles.discard}
                        onClick={(ev) => {
                            ev.preventDefault();
                            onDiscard();
                        }}
                    >
                        Descartar
                    </button>
                    <button
                        className={styles.confirm}
                        onClick={onCreation}
                        disabled={formState === 'loading'}
                    >
                        {formState === 'idle' ? (
                            'Crear usuario'
                        ) : (
                            <>
                                <Loader size={16} color={'white'} />
                                Cargando...
                            </>
                        )}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateUserModal;
