import { MouseEvent } from 'react';

interface Props {
    color?: string;
    size?: number;
    stroke?: number;
    className?: string;
    onClick?: (ev: MouseEvent) => void;
}

const CloseIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 24}
            height={props.size || 24}
            viewBox="0 0 24 24"
            strokeWidth={props.stroke ?? 1.5}
            stroke={props.color || '#565454'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={props.className}
            onClick={props.onClick}
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M18 6l-12 12" />
            <path d="M6 6l12 12" />
        </svg>
    );
};

export default CloseIcon;
