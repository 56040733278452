const DarkTheme = () => (
    <svg
        width="200"
        height="100"
        viewBox="0 0 200 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="200" height="100" rx="6" fill="#525D72" />
        <mask id="path-2-inside-1_1677_153" fill="white">
            <path d="M0 6C0 2.68629 2.68629 0 6 0H35V100H6C2.68629 100 0 97.3137 0 94V6Z" />
        </mask>
        <path
            d="M0 6C0 2.68629 2.68629 0 6 0H35V100H6C2.68629 100 0 97.3137 0 94V6Z"
            fill="#394253"
        />
        <path
            d="M0 0H35H0ZM35 100H0H35ZM0 100V0V100ZM36 0V100H34V0H36Z"
            fill="#DDDDDD"
            mask="url(#path-2-inside-1_1677_153)"
        />
        <mask id="path-4-inside-2_1677_153" fill="white">
            <path d="M0 6C0 2.68629 2.68629 0 6 0H194C197.314 0 200 2.68629 200 6V16H0V6Z" />
        </mask>
        <path
            d="M0 6C0 2.68629 2.68629 0 6 0H194C197.314 0 200 2.68629 200 6V16H0V6Z"
            fill="#394253"
        />
        <path
            d="M0 0H200H0ZM200 17H0V15H200V17ZM0 16V0V16ZM200 0V16V0Z"
            fill="#DDDDDD"
            mask="url(#path-4-inside-2_1677_153)"
        />
        <rect x="4" y="22" width="26" height="4" rx="1" fill="#DDDDDD" />
        <rect x="47" y="28" width="37" height="2" rx="1" fill="#DDDDDD" />
        <rect x="47" y="40" width="37" height="2" rx="1" fill="#DDDDDD" />
        <rect x="47" y="43" width="37" height="1" rx="0.5" fill="#FCA352" />
        <rect x="47" y="34" width="37" height="2" rx="1" fill="#DDDDDD" />
        <rect x="4" y="52" width="26" height="4" rx="1" fill="#DDDDDD" />
        <rect x="4" y="32" width="26" height="4" rx="1" fill="#DDDDDD" />
        <rect x="4" y="42" width="26" height="4" rx="1" fill="#DDDDDD" />
        <rect
            x="43.5"
            y="23.5"
            width="44"
            height="69"
            rx="3.5"
            stroke="#DDDDDD"
        />
        <circle cx="78" cy="84" r="5" fill="#635BFF" />
        <circle cx="9" cy="8" r="2" fill="#FE5F58" />
        <circle cx="27" cy="8" r="2" fill="#28C840" />
        <circle cx="18" cy="8" r="2" fill="#FEBC30" />
        <rect x="166" y="21" width="27" height="10" rx="2" fill="#635BFF" />
        <circle cx="167" cy="22" r="3" fill="#FCA352" />
    </svg>
);

export default DarkTheme;
