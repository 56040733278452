import { useState } from 'react';

import { useAuthStore } from '../../store';
import Logger from '../../utils/logger.util';
import { useNavigation } from '../../hooks/navigation.hook';
import { Credentials } from '../../types/credentials.type';

import LoginForm from './login-form';
import styles from './login.module.scss';

const LoginView = () => {
    const { login } = useAuthStore();
    const { navigate } = useNavigation();

    const [errorMessage, setErrorMessage] = useState<string>();

    const onLogin = async (creds: Credentials) => {
        const { error } = await login(creds);
        if (error) {
            Logger.error('Login error:', error);
            return setErrorMessage(error);
        }
        navigate('/');
    };

    return (
        <main className={'view'}>
            <div className={styles.content}>
                <LoginForm onSubmit={onLogin} failureMessage={errorMessage} />
            </div>
        </main>
    );
};

export default LoginView;
