const macRegex = /mac/i;

const getOS = () => {
    const agent = navigator.userAgent;
    if (macRegex.test(agent)) return 'mac';

    return 'windows';
};

export { getOS };
