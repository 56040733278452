import { petitionSdk } from '../services/petition.service';
import ResponseCodes from '../constants/response-codes.constant';

export const initialState = 'CREATED';

type MachineStep = {
    currentStatus: number;
    nextStatus: number;
    nextState: string;
    text: string;
    transition: ((id: number) => Promise<MachineStep>) | null;
};

type StateMachine = {
    [key: string]: MachineStep;
};

export const petitionStateMachine: StateMachine = {
    CREATED: {
        currentStatus: 1,
        nextStatus: 2,
        nextState: 'RECEIVED',
        text: 'Aceptar',
        transition: (id: number) =>
            updatePetitionStatus({
                id,
                status: 2,
                nextStep: 'RECEIVED',
                currStep: 'CREATED',
            }),
    },
    RECEIVED: {
        currentStatus: 2,
        nextStatus: 3,
        nextState: 'SEND_OPERATORS',
        text: 'Enviar operadores',
        transition: (id: number) =>
            updatePetitionStatus({
                id,
                status: 3,
                nextStep: 'SEND_OPERATORS',
                currStep: 'RECEIVED',
            }),
    },
    SEND_OPERATORS: {
        currentStatus: 3,
        nextStatus: 4,
        nextState: 'OPS_ON_SITE',
        text: 'Operadores en el sitio',
        transition: (id: number) =>
            updatePetitionStatus({
                id,
                status: 4,
                nextStep: 'OPS_ON_SITE',
                currStep: 'SEND_OPERATORS',
            }),
    },
    OPS_ON_SITE: {
        currentStatus: 4,
        nextStatus: 5,
        nextState: 'FINISHED',
        text: 'Finalizar',
        transition: (id: number) =>
            updatePetitionStatus({
                id,
                status: 5,
                nextStep: 'FINISHED',
                currStep: 'OPS_ON_SITE',
            }),
    },
    FINISHED: {
        currentStatus: 5,
        nextStatus: -1,
        nextState: '',
        text: '',
        transition: null,
    },
};

const updatePetitionStatus = async (data: {
    id: number;
    status: number;
    nextStep: string;
    currStep: string;
}): Promise<MachineStep> => {
    const response = await petitionSdk.updateStatus({
        id: data.id,
        status: data.status,
    });
    return petitionStateMachine[
        response.code === ResponseCodes.PROCESS_OK
            ? data.nextStep
            : data.currStep
    ];
};

export const mapStatusToStep: { [key: number]: string } = {
    1: 'CREATED',
    2: 'RECEIVED',
    3: 'SEND_OPERATORS',
    4: 'OPS_ON_SITE',
    5: 'FINISHED',
};
