import { useState, MouseEvent, useRef, useEffect } from 'react';

import DropdownIcon from '../../icons/dropdown.icon';
import { useDebounce } from '../../hooks/debounce.hook';
import { Company } from '../../types/company.type';
import CloseIcon from '../../icons/close.icon';

import styles from './search-company-input.module.scss';
import SearchModal from './search-modal';

interface Props {
    list: Company[];
    onSearch: (query: string) => void;
    onSelect: (id: number) => void;
    onClear: () => void;
}

const SearchCompanyInput = ({ list, onSearch, onSelect, onClear }: Props) => {
    const mainButtonRef = useRef<HTMLButtonElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [companyName, setCompanyName] = useState('');
    const [query, setQuery] = useState('');
    const searchQuery = useDebounce(query, 1000);
    const [modalShown, setModalShown] = useState(false);

    useEffect(() => {
        onSearch(searchQuery);
    }, [searchQuery]);

    const toggleModal = (ev: MouseEvent) => {
        ev.preventDefault();
        setModalShown(!modalShown);
    };

    const companySelect = (company: Company) => {
        setModalShown(false);
        setCompanyName(company.displayName);
        onSelect(company.id);
    };

    const clearCompany = () => {
        setModalShown(false);
        setCompanyName('');
        onClear();
    };

    return (
        <div className={styles.container}>
            <button
                ref={mainButtonRef}
                className={styles.mainButton}
                onClick={toggleModal}
            >
                <span>
                    {companyName
                        ? `Companía: ${companyName}`
                        : 'Selecciona una compañía'}
                </span>
                {companyName && <CloseIcon size={18} onClick={clearCompany} />}
                <div className={styles.dropdownContainer}>
                    <DropdownIcon size={18} color={'#414552'} />
                </div>
            </button>
            <SearchModal
                ref={modalRef}
                visibility={modalShown}
                onSearch={setQuery}
                companies={list}
                onSelect={companySelect}
            />
        </div>
    );
};

export default SearchCompanyInput;
