import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Operator } from '../types/operator.type';
import { isCoordinator } from '../utils/role.util';

export const useIsCoordinator = (profile: Operator) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!profile) return;

        if (profile && isCoordinator(profile.role.name)) return;

        return navigate('/');
    }, [profile]);
};
