import classNames from 'classnames';

import styles from './loader.module.scss';

interface Props {
    size?: number;
    color?: string;
    className?: string;
}

const Loader = ({ size, color, className }: Props) => {
    return (
        <span
            style={{
                borderColor: color ?? '#ccc',
                borderBottomColor: 'transparent',
                width: size ?? 48,
                height: size ?? 48,
                borderWidth: size ? size * 0.14 : 5,
            }}
            className={classNames([styles.loader, className])}
        />
    );
};

export default Loader;
