interface Props {
    color?: string;
    size?: number;
}

const EmergencyIcon = (props: Props) => {
    return (
        <svg
            width={props.size ?? 646}
            height={props.size ?? 560}
            viewBox="0 0 646 560"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M161.5 3.05176e-05L0 279.726L161.5 559.452H484.5L646 279.726L484.5 0L161.5 3.05176e-05ZM354.409 333.817L361.795 133.817H282.25L289.636 333.817H354.409ZM293.756 417.766C301.615 425.436 311.038 429.272 322.023 429.272C329.03 429.272 335.517 427.52 341.483 424.016C347.449 420.418 352.278 415.635 355.972 409.669C359.76 403.609 361.701 396.885 361.795 389.499C361.701 378.704 357.629 369.471 349.58 361.8C341.625 354.13 332.439 350.294 322.023 350.294C311.038 350.294 301.615 354.13 293.756 361.8C285.99 369.471 282.155 378.704 282.25 389.499C282.155 400.579 285.99 410.001 293.756 417.766Z"
                fill={props.color ?? '#FE5F58'}
            />
        </svg>
    );
};

export default EmergencyIcon;
