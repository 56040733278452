const UserRoles = {
    ADMIN: 1,
    CMC: 2,
    COORDINATOR: 3,
    OPERATOR: 4,
    USER: 5,
    SUPER_ADMIN: 6,
};

export default UserRoles;
