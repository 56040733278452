export const PetitionStatusColors = {
    ACTIVE: {
        value: 'En progreso',
        txtColor: '#af9807',
        bgColor: '#f7f1d5',
    },
    COMPLETED: {
        value: 'Culminada',
        txtColor: '#07AF25',
        bgColor: '#D5F7DB',
    },
    CANCELLED: {
        value: 'Cancelada',
        txtColor: '#af1507',
        bgColor: '#f7d5d5',
    },
};
