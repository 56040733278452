import { ReactNode } from 'react';
import classNames from 'classnames';

import GoBack from '../../components/go-back/go-back';
import Toggle from '../../components/toggle/toggle';
import Divider from '../../components/divider/divider';
import ButtonKey from '../../components/button-key/button-key';
import { useSettingsStore } from '../../store';

import styles from './settings.module.scss';
import ThemeSelection from './theme-selection';

const SettingsView = () => {
    const { shortcuts, menuCollapsable, toggleMenuCollapsable, theme } =
        useSettingsStore();

    const renderShortcuts = (): ReactNode[] => {
        return shortcuts.map((shortcut, idx) => (
            <div key={idx} className={styles.shortcutRow}>
                <span>{shortcut.displayText} →</span>
                {shortcut.keys.map((key, idx) => (
                    <div
                        key={idx}
                        className={'flex align-center'}
                        style={{ gap: 4 }}
                    >
                        <ButtonKey>{key}</ButtonKey>
                        {idx !== shortcut.keys.length - 1 && <span>+</span>}
                    </div>
                ))}
            </div>
        ));
    };

    return (
        <main
            className={classNames([
                'view',
                theme.current === 'light' ? 'light' : 'dark',
            ])}
        >
            <div className={'viewContent'}>
                <header>
                    <GoBack theme={theme.current} />
                    <h1 className={styles.title}>Ajustes</h1>
                </header>
                <section>
                    <div className={styles.toggles}>
                        <div className={styles.row}>
                            <span>Hacer el menú desplegable</span>
                            <Toggle
                                default={menuCollapsable}
                                onToggle={toggleMenuCollapsable}
                            />
                        </div>
                        <div className={styles.col}>
                            <span>Tema (color de la interfaz)</span>
                            <ThemeSelection
                                onSelect={theme.change}
                                defaultValue={theme.current}
                            />
                        </div>
                    </div>
                    <Divider />
                    <h5>Apariencia</h5>
                    <Divider />
                    <h5>Lista de teclas rápidas (shortcuts)</h5>
                    {renderShortcuts()}
                </section>
            </div>
        </main>
    );
};

export default SettingsView;
