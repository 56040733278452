import React, { useState, MouseEvent, useRef, useEffect } from 'react';
import { format as formatDate } from 'date-fns';
import { es } from 'date-fns/locale';

import CloseIcon from '../../icons/close.icon';
import { DateRange } from '../../types/date-range.type';

import styles from './date-picker.module.scss';
import { DatePickerModal } from './modal';
import DateIcon from './date-icon';

interface IDate {
    day: number;
    date: number;
    month: number;
    year: number;
}

interface Props {
    onDate?: (date: Date) => void;
    onClear?: () => void;
    clearable?: boolean;
    isRange?: boolean;
    onRange?: (range: DateRange) => void;
}

const DatePicker = ({
    onDate,
    onClear,
    clearable,
    isRange,
    onRange,
}: Props) => {
    const mainButtonRef = useRef<HTMLButtonElement | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);

    const [date, setDate] = useState(new Date());
    const [modalShown, setModalShown] = useState(false);
    const [modalPos, setModalPos] = useState({ x: 34, y: 0 });
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [daysOfMonth, setDaysOfMonth] = useState<IDate[]>([]);
    const [selected, setSelected] = useState(false);
    const [dateRange, setDateRange] = useState<DateRange>({});

    useEffect(() => {
        onModalFocus();
        onModalBlur();
    }, []);

    useEffect(() => {
        getDaysOfCurrentMonth();
    }, [currentMonth]);

    const onModalBlur = () => {
        modalRef.current!.onblur = () => {
            setTimeout(() => {
                setModalShown(false);
            }, 100);
        };
    };

    const onModalFocus = () => {
        modalRef.current!.onfocus = () => {
            setModalShown(true);
        };
    };

    const getDaysOfCurrentMonth = () => {
        const date = new Date();
        date.setDate(1); // Set it to the first day of the month
        date.setMonth(currentMonth.getMonth());
        date.setFullYear(currentMonth.getFullYear());
        date.setHours(0, 0, 0, 0); // Set date to midnight: timestamp 00:00:00

        const listOfDays = [];
        while (date.getMonth() === currentMonth.getMonth()) {
            listOfDays.push({
                day: date.getDay(),
                date: date.getDate(),
                month: date.getMonth(),
                year: date.getFullYear(),
            });

            date.setDate(date.getDate() + 1);
        }
        setDaysOfMonth(listOfDays);
    };

    const updateCurrentMonth = (leap: number) => {
        const newMonth = currentMonth.setMonth(currentMonth.getMonth() + leap);
        setCurrentMonth(new Date(newMonth));
    };

    const toggleShow = (ev: MouseEvent) => {
        ev.preventDefault();
        if (modalShown) {
            return modalRef.current?.blur();
        }
        modalRef.current?.focus();

        const modalHeight = modalRef.current!.offsetHeight + 4; // 4px of spacing;

        const bottomCapacity =
            Number(mainButtonRef.current?.getBoundingClientRect().bottom) +
            modalHeight;
        const maxHeight = window.innerHeight;
        setModalPos({
            x: bottomCapacity >= maxHeight ? -modalHeight : 34,
            y: 0,
        });
    };

    const onDateSelect = (date: IDate) => {
        const selectedDate = new Date(date.year, date.month, date.date);
        setDate(selectedDate);
        setSelected(true);
        onDate?.(selectedDate);
        modalRef.current?.blur();
    };

    const selectRange = (range: DateRange) => {
        modalRef.current?.blur();
        setDateRange(range);
        setSelected(true);
        onRange?.(range);
    };

    const clearDate = (ev: MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        if (!clearable) return;
        setModalShown(false);
        setSelected(false);
        setCurrentMonth(new Date());
        setDate(new Date());
        setDateRange({});
        onClear?.();
    };

    const formatDateRange = (range: DateRange) => {
        const from = formatDate(range.from!, 'dd MMMM, yyyy', { locale: es });
        const to = formatDate(range.to!, 'dd MMMM, yyyy', { locale: es });

        return `${from} - ${to}`;
    };

    return (
        <div className={styles.container}>
            <button
                ref={mainButtonRef}
                onClick={toggleShow}
                className={styles.mainButton}
            >
                <DateIcon size={16} color={'#414552'} />
                <span>
                    {isRange && dateRange.to
                        ? formatDateRange(dateRange)
                        : formatDate(date, 'dd MMMM, yyyy', { locale: es })}
                </span>
                {clearable && selected && (
                    <CloseIcon size={18} onClick={clearDate} />
                )}
            </button>
            <DatePickerModal
                ref={modalRef}
                days={daysOfMonth}
                position={modalPos}
                visibility={modalShown}
                onDateSelect={onDateSelect}
                isRange={isRange}
                onRange={selectRange}
                prevMonth={() => updateCurrentMonth(-1)}
                nextMonth={() => updateCurrentMonth(1)}
            />
        </div>
    );
};

export default DatePicker;
