interface Props {
    orientation?: string;
    thickness?: number;
    color?: string;
}

const HDivider = ({ orientation = 'horizontal', thickness, color }: Props) => {
    return (
        <div
            style={{
                width: orientation === 'horizontal' ? '100%' : thickness ?? 1,
                height: orientation === 'vertical' ? '100%' : thickness ?? 1,
                backgroundColor: color ?? '#d8dee7',
            }}
        />
    );
};

export default HDivider;
