import { MouseEvent, useEffect, useState } from 'react';

import { petitionSdk } from '../../../services/petition.service';
import PlaceAutocomplete from '../../../components/place-autocomplete/place-autocomplete';
import Modal from '../../../components/modal/modal';
import Map from '../../../components/map/map';
import Button from '../../../components/button/button';
import { LatLng } from '../../../types/latlng.type';

import MapCenterIcon from './map-center-icon';
import styles from './location-selection.module.scss';

type Place = {
    address: string;
    lat: number;
    lng: number;
};

interface Props {
    onSelect: (data: Place) => void;
}

const LocationSelection = ({ onSelect }: Props) => {
    const [placeSuggestion, setPlaceSuggestion] = useState([]);
    const [place, setPlace] = useState<{
        choosingType?: string;
        place?: Place;
    }>({
        choosingType: undefined,
        place: undefined,
    });
    const [mapModalShown, setMapModalShown] = useState(false);

    useEffect(() => {
        place.place && onSelect(place.place);
    }, [place.place]);

    const autocomplete = async (address: string) => {
        const response = await petitionSdk.autocompleteAddress(address);
        setPlaceSuggestion(response.data);
        setPlace((prev) => ({ ...prev, suggestions: response.data }));
    };

    const fetchPlaceDetails = async (id: string) => {
        setPlaceSuggestion([]);
        const response = await petitionSdk.getPlaceDetails(id);
        const { address, location } = response.data;
        setPlace((prev) => ({
            ...prev,
            place: {
                address,
                lat: location.lat,
                lng: location.lng,
            },
        }));
    };

    const chooseLocationInput = (ev: MouseEvent, type: 'address' | 'map') => {
        ev.preventDefault();
        setPlace((prev) => ({ ...prev, choosingType: type }));
        type === 'map' && setMapModalShown(true);
    };

    const onMapLocation = async (latLng: LatLng) => {
        setMapModalShown(false);
        const response = await petitionSdk.reverseGeocode(latLng);
        setPlace((prev) => ({
            ...prev,
            place: {
                ...latLng,
                address: response.data,
            },
        }));
    };

    return (
        <section>
            <label>¿Dónde es la emergencia?</label>
            <div className={styles.formSection}>
                <span>
                    Puedes escribir la direcicón o seleccionar el punto en el
                    mapa.
                </span>
                <div className={'flex space-between'}>
                    <button
                        className={styles.chooseLocationButton}
                        onClick={(ev) => chooseLocationInput(ev, 'address')}
                    >
                        Escribir la dirección
                    </button>
                    <button
                        className={styles.chooseLocationButton}
                        onClick={(ev) => chooseLocationInput(ev, 'map')}
                    >
                        Escoger en el mapa
                    </button>
                </div>
                {place.choosingType === 'address' && !place.place?.address && (
                    <PlaceAutocomplete
                        placeholder={'Indica dónde es'}
                        places={placeSuggestion}
                        onSearch={autocomplete}
                        onSelect={fetchPlaceDetails}
                        onClear={() => setPlaceSuggestion([])}
                    />
                )}
                <MapModal
                    shown={mapModalShown}
                    onDiscard={() => setMapModalShown(false)}
                    onLocation={onMapLocation}
                />
                {place.place?.address && <span>{place.place.address}</span>}
            </div>
        </section>
    );
};

interface ModalProps {
    shown: boolean;
    onDiscard: () => void;
    onLocation: (latLng: LatLng) => void;
}

const MapModal = ({ shown, onDiscard, onLocation }: ModalProps) => {
    const [mapCenter, setMapCenter] = useState<LatLng>();

    return (
        <Modal shown={shown} onDiscard={onDiscard}>
            <div className={styles.modal}>
                <div className={styles.mapContainer}>
                    <Map onDragEnd={(data) => setMapCenter(data.center)} />
                    <div className={styles.mapIconContainer}>
                        <MapCenterIcon />
                    </div>
                </div>
                <p>
                    El ícono central en el mapa representa el lugar que
                    seleccionarás como ubicación de la emergencia. Para cambiar
                    dicha ubicación, mueve el mapa a la dirección deseada.
                </p>
                <div
                    className={'flex justify-center'}
                    style={{ marginTop: 20 }}
                >
                    <Button onPress={() => onLocation(mapCenter!)}>
                        Confirmar
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default LocationSelection;
