import { PropsWithChildren } from 'react';
import classNames from 'classnames';

import styles from './modal.module.scss';

interface Props extends PropsWithChildren<any> {
    shown: boolean;
    onDiscard: () => void;
    dismissible?: boolean;
}

const Modal = ({ shown, children, onDiscard, dismissible = true }: Props) => {
    const onDismiss = () => {
        dismissible && onDiscard();
    };

    return (
        <div
            className={classNames({
                [styles.container]: true,
                [styles.shown]: shown,
            })}
        >
            <div className={styles.fade} onClick={onDismiss} />
            <div className={styles.modal}>{children}</div>
        </div>
    );
};

export default Modal;
