import { useEffect, useState } from 'react';
import classNames from 'classnames';

import SearchIcon from '../../icons/search.icon';
import { useDebounce } from '../../hooks/debounce.hook';
import CloseIcon from '../../icons/close.icon';

import styles from './search-input.module.scss';

interface Props {
    onSearch: (value: string) => void;
    className?: string;
}

const SearchInput = ({ onSearch, className }: Props) => {
    const [searchValue, setSearchValue] = useState('');
    const debouncedValue = useDebounce(searchValue, 800);

    useEffect(() => {
        if (!debouncedValue || debouncedValue.length >= 3) {
            onSearch(debouncedValue);
        }
    }, [debouncedValue]);

    const clearInput = () => setSearchValue('');

    return (
        <div className={classNames([styles.searchInput, className])}>
            <div className={styles.searchIconContainer}>
                <SearchIcon size={18} color={'#697693'} />
            </div>
            <input
                placeholder={'Buscar usuario'}
                value={searchValue}
                onChange={(ev) => setSearchValue(ev.target.value)}
            />
            {searchValue && (
                <div className={styles.closeContainer} onClick={clearInput}>
                    <CloseIcon size={18} color={'#999'} />
                </div>
            )}
        </div>
    );
};

export default SearchInput;
