/// <reference types="@types/google.maps" />
import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';

import EmergencyIcon from '../../icons/emergency.icon';
import { LatLng } from '../../types/latlng.type';
import CenterLocationIcon from '../../icons/center-location.icon';
import { ThemeType } from '../../types/theme.type';

import styles from './map.module.scss';

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY as string;

type Marker = {
    id?: any;
    lat: number;
    lng: number;
    text?: string;
    isFinished?: boolean;
};

interface MapProps {
    defaultZoom?: number;
    center?: { lat: number; lng: number };
    markers?: Marker[];
    onMarkerClick?: (id?: any) => void;
    theme?: ThemeType;
    showEmergenciesMessage?: boolean;
    showCenteringButton?: boolean;
    onDragEnd?: (data: { center: LatLng }) => void;
}

const handleMapOnApiLoaded = (map: any) => {
    map.setOptions({
        disableDefaultUI: true,
        styles: [
            {
                featureType: 'all',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'on',
                    },
                ],
            },
        ],
    });
};

const Map = (props: MapProps) => {
    const [map, setMap] = useState<any>();
    const [currentZoom, setCurrentZoom] = useState(12);

    const fitMapToMarkerBounds = (map: google.maps.Map, markers: LatLng[]) => {
        if (markers.length === 0) return;
        const bounds = new window.google.maps.LatLngBounds();
        markers.map((marker) => {
            bounds.extend(marker);
        });
        map.fitBounds(bounds);
        if (markers.length === 1) {
            map.setZoom(12);
        }
    };

    useEffect(() => {
        if (!map) return;
        if (!props.markers || props.markers.length === 0) return;

        fitMapToMarkerBounds(map, props.markers);
    }, [map, props.markers]);

    const centerLocation = () => {
        setCurrentZoom(12);
        map?.moveCamera({
            zoom: 12,
            center: {
                lat: 10.484676,
                lng: -66.831576,
            },
        });
    };

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAPS_KEY }}
                defaultZoom={currentZoom}
                yesIWantToUseGoogleMapApiInternals={true}
                defaultCenter={
                    props.center ?? {
                        lat: 10.484676,
                        lng: -66.831576,
                    }
                }
                onDragEnd={(end) => {
                    props.onDragEnd?.({
                        center: {
                            lat: end.center.lat(),
                            lng: end.center.lng(),
                        },
                    });
                }}
                onZoomAnimationEnd={setCurrentZoom}
                onGoogleApiLoaded={({ map }) => {
                    setMap(map);
                    handleMapOnApiLoaded(map);
                }}
            >
                {props.markers &&
                    props.markers.map((marker, idx) => (
                        <MapMarker
                            key={idx}
                            id={marker.id}
                            lat={marker.lat}
                            lng={marker.lng}
                            onClick={props.onMarkerClick}
                            color={marker.isFinished ? '#0dc648' : '#FE5F58'}
                        />
                    ))}
            </GoogleMapReact>
            {!props.markers?.length && props.showEmergenciesMessage && (
                <div className={styles.noEmergencies}>
                    No hay emergencias activas
                </div>
            )}
            {props.showCenteringButton && (
                <CenteringButton onPress={centerLocation} />
            )}
        </div>
    );
};

interface MapMarkerProps {
    id?: any;
    lat: number;
    lng: number;
    text?: string;
    onClick?: (id: any) => void;
    color?: string;
}

const MapMarker = (props: MapMarkerProps) => {
    return (
        <div
            className={styles.mapMarker}
            onClick={() => props.onClick?.(props.id)}
        >
            <EmergencyIcon size={30} color={props.color} />
        </div>
    );
};

const CenteringButton = ({ onPress }: { onPress: () => void }) => {
    return (
        <div className={styles.centeringButton} onClick={onPress}>
            <CenterLocationIcon />
        </div>
    );
};

export default Map;
