import { forwardRef } from 'react';
import classNames from 'classnames';

import { Company } from '../../types/company.type';

import styles from './search-modal.module.scss';

interface Props {
    visibility: boolean;
    onSearch: (query: string) => void;
    companies: Company[];
    onSelect: (company: Company) => void;
}

const SearchModal = forwardRef((props: Props, ref: any) => {
    return (
        <div
            ref={ref}
            tabIndex={-1}
            className={classNames({
                [styles.modal]: true,
                [styles.shown]: props.visibility,
            })}
        >
            <label>Escribe el nombre de la compañía:</label>
            <input
                placeholder={'Compañía'}
                onChange={(ev) => props.onSearch(ev.target.value.trim())}
            />
            {props.companies.length > 0 && (
                <ul>
                    {props.companies.map((company) => (
                        <li
                            key={company.id}
                            onClick={() => props.onSelect(company)}
                        >
                            {company.displayName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
});

export default SearchModal;
