import { useState } from 'react';
import classNames from 'classnames';

import Map from '../../components/map/map';
import { usePetitionStore, useSettingsStore, useUserStore } from '../../store';
import PetitionCard from '../../components/petition-card/petition-card';
import ReasonsModal from '../../components/reasons-modal/reasons-modal';
import { petitionSdk } from '../../services/petition.service';
import ResponseCodes from '../../constants/response-codes.constant';
import Logger from '../../utils/logger.util';
import { useFetchBaseConfig } from '../../hooks/base-config.hook';
import PetitionMetadataCard from '../../components/petition-metadata-card/petition-metadata-card';

import styles from './home.module.scss';

const HomeView = () => {
    useFetchBaseConfig();

    const { theme } = useSettingsStore();
    const petitionState = usePetitionStore();
    const { getById: getUser } = useUserStore();

    const [showCardDetails, setShowCardDetails] = useState(false);
    const [showMetadata, setShowMetadata] = useState(false);
    const [showReasonsModal, setShowReasonsModal] = useState(false);
    const [cancellingPetition, setCancellingPetition] = useState<number>();

    const selectPetition = async (id: number) => {
        const petition = petitionState.actives.find(
            (petition) => petition.id === id
        );
        const user = await getUser(petition!.user!.id);
        user && petitionState.selectPetition({ ...petition!, user });
        setShowCardDetails(true);
    };

    const discardPetition = (id: number) => {
        setShowCardDetails(false);
        petitionState.discardPetition(id);
    };

    const closeDetails = () => {
        setShowCardDetails(false);
        setShowMetadata(false);
    };

    const requestPetitionCancellation = (id: number) => {
        setCancellingPetition(id);
        setShowReasonsModal(true);
    };

    const cancelPetition = async (reasonId: number) => {
        setShowReasonsModal(false);
        if (!cancellingPetition) return;
        const response = await petitionSdk.cancelPetition(
            cancellingPetition,
            reasonId
        );
        if (response.code !== ResponseCodes.PROCESS_OK) {
            return Logger.error('Error cancelling petition:', response);
        }
        setShowCardDetails(false);
        setShowMetadata(false);
        petitionState.selectPetition(undefined);
        petitionState.removeFromActives(cancellingPetition);
    };

    return (
        <main
            className={classNames(['view', theme.current === 'dark' && 'dark'])}
        >
            {petitionState.selected && (
                <>
                    <PetitionCard
                        petition={petitionState.selected}
                        shown={showCardDetails}
                        onDismiss={closeDetails}
                        onDiscardPetition={discardPetition}
                        onOpenMetadata={() => setShowMetadata(true)}
                        onCancelPetition={requestPetitionCancellation}
                    />
                    <PetitionMetadataCard
                        shown={showMetadata}
                        onDismiss={() => setShowMetadata(false)}
                    />
                </>
            )}
            {/*
            {showTutorial && lastIndexTipSeen === 0 && (
                <Tutorial onNext={() => setLastIndexTipSeen(1)} />
            )}
            */}
            {/* style={{ zIndex: 999 }} justo el div de abajo */}
            <div className={styles.mapContainer}>
                <Map
                    showEmergenciesMessage={true}
                    theme={theme.current}
                    markers={petitionState.actives}
                    onMarkerClick={selectPetition}
                    showCenteringButton={true}
                />
            </div>
            <ReasonsModal
                shown={showReasonsModal}
                reasons={petitionState.cancellationReasons}
                onDiscard={() => setShowReasonsModal(false)}
                onConfirm={cancelPetition}
            />
        </main>
    );
};

// const Tutorial = ({ onNext }: { onNext: () => void }) => {
//     return (
//         <TutorialTip
//             position={'left'}
//             onNext={onNext}
//             currentTipIndex={1}
//             numberOfTips={3}
//         >
//             <h5>Mapa de solicitudes</h5>
//             <span>
//                 Aquí se mostrarán todas las solicitudes en curso.
//                 <br />
//                 <br />
//                 Haz click en el marcador rojo para abrir los detalles de una
//                 solicitud.
//             </span>
//         </TutorialTip>
//     );
// };

export default HomeView;
