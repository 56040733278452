import { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { Realtime } from './realtime/socket';
import styles from './app.module.scss';
import MainMenu from './components/main-menu/main-menu';
import { useAuthStore, usePetitionStore, useSettingsStore } from './store';
import EmergencyAudio from './components/emergency-audio/emergency-audio';
import { Views } from './views';
import { useEventHooks } from './hooks/events.hook';
import { useNavigation } from './hooks/navigation.hook';
import LoginView from './views/login/login.view';
import PetitionsReportView from './views/petitions-report/petitions-report.view';

const App = () => {
    const { navigate } = useNavigation();
    useEventHooks(navigate);

    const { accessToken, fetchProfile, logout } = useAuthStore();
    const petitionStore = usePetitionStore();
    const { initShortcuts, theme } = useSettingsStore();

    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        initRealtime();
        initShortcuts();
    }, []);

    useEffect(() => {
        if (!accessToken) {
            return navigate('/login');
        }
        fetchProfile();
        petitionStore.fetchMotives(accessToken);
    }, [accessToken]);

    useEffect(() => {
        setPlaySound(petitionStore.alertNewOne);
    }, [petitionStore.alertNewOne]);

    const initRealtime = () => {
        Realtime.subscribe(
            { channel: 'emergencies', event: 'newPetition' },
            (message: any) => {
                petitionStore.addNewActive(message.data);
            }
        );
        Realtime.subscribe(
            { channel: 'emergencies', event: 'petitionStatusUpdated' },
            (message: any) => {
                const { id, status } = message.data;
                petitionStore.updateStatus(id, status);
            }
        );
        Realtime.subscribe(
            {
                channel: 'emergencies',
                event: 'petitionCancelled',
            },
            (message: any) => {
                const { id, reason } = message.data;
                petitionStore.cancelPetition(id, reason);
            }
        );
        Realtime.subscribe(
            {
                channel: 'emergencies',
                event: 'petitionFinished',
            },
            (message: any) => {
                const { id } = message.data;
                petitionStore.finishPetition(id);
            }
        );
    };

    const onLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <main id={styles.app}>
            <Routes>
                <Route path="/login" element={<LoginView />} />
                <Route
                    element={
                        <>
                            <MainMenu
                                theme={theme.current}
                                onLogout={onLogout}
                            />
                            <Outlet />
                        </>
                    }
                >
                    {Views.map((view, key) => (
                        <Route
                            key={key}
                            path={view.path}
                            element={view.element}
                        />
                    ))}
                </Route>
                <Route
                    path={'/petitions-report'}
                    element={<PetitionsReportView />}
                />
            </Routes>
            <EmergencyAudio active={playSound} />
        </main>
    );
};

export default App;
