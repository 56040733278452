interface Props {
    color?: string;
    size?: number;
}

const SadFaceIcon = ({ color, size }: Props) => {
    return (
        <svg
            width={size ?? 24}
            height={size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <g>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM8.5 8C7.67157 8 7 8.67157 7 9.5C7 10.3284 7.67157 11 8.5 11C9.32843 11 10 10.3284 10 9.5C10 8.67157 9.32843 8 8.5 8ZM15.5 8C14.6716 8 14 8.67157 14 9.5C14 10.3284 14.6716 11 15.5 11C16.3284 11 17 10.3284 17 9.5C17 8.67157 16.3284 8 15.5 8ZM6.88 17.5C7.68 15.45 9.67 14 12 14C14.33 14 16.32 15.45 17.12 17.5H15.45C14.75 16.31 13.48 15.5 12 15.5C10.52 15.5 9.24 16.31 8.55 17.5H6.88ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12Z"
                    fill={color ?? '#565454'}
                />
            </g>
        </svg>
    );
};

export default SadFaceIcon;
