interface Props {
    color?: string;
    size?: number;
}

const PlusIcon = (props: Props) => {
    return (
        <svg
            width={props.size || 44}
            height={props.size || 44}
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke={props.color || '#565454'}
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M12 5l0 14" />
            <path d="M5 12l14 0" />
        </svg>
    );
};

export default PlusIcon;
