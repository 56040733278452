import { FocusEvent, useEffect, useState } from 'react';
import classNames from 'classnames';

import PlusIcon from '../../icons/plus.icon';
import { Operator } from '../../types/operator.type';
import { normalize } from '../../utils/string.util';
import Checkbox from '../checkbox/checkbox';

import styles from './select-operator.module.scss';
import AvatarSvg from './avatar.svg';

interface SelectorProps {
    operators: (Operator & { selected: boolean })[];
    onSelect: (id: number) => void;
}

const SelectOperator = ({ operators, onSelect }: SelectorProps) => {
    const [selectionShown, setSelectionShown] = useState(false);
    const [listShown, setListShown] = useState(false);
    const [filterName, setFilterName] = useState('');
    const [inputPosition, setInputPosition] = useState({
        top: 0,
        left: 0,
        width: 0,
    });

    const showList = (ev: FocusEvent) => {
        const domRect = (ev.target as HTMLInputElement).getBoundingClientRect();
        setInputPosition({
            top: domRect.bottom - 1,
            left: domRect.left,
            width: domRect.width,
        });
        setListShown(true);
    };

    return (
        <section className={styles.container}>
            <ul className={styles.selectedList}>
                <li className={styles.card}>
                    <div className={styles.content}>
                        <div
                            className={styles.iconContainer}
                            onClick={() => setSelectionShown(!selectionShown)}
                        >
                            <PlusIcon color={'#909c9d'} size={24} />
                        </div>
                        <div className={styles.nameContainer}>
                            <span>Añadir operador</span>
                        </div>
                    </div>
                </li>
                {operators
                    .filter((operator) => operator.selected)
                    .map((operator, idx) => (
                        <li key={idx} className={styles.card}>
                            <div className={styles.content}>
                                <div className={styles.imageContainer}>
                                    <img src={operator.avatar || AvatarSvg} />
                                </div>
                                <div className={styles.nameContainer}>
                                    <span>
                                        {operator.firstName} {operator.lastName}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>

            {selectionShown && (
                <div className={styles.selection}>
                    <input
                        className={classNames({ [styles.focused]: listShown })}
                        placeholder={'Nombre del operador'}
                        onChange={(ev) => setFilterName(ev.target.value.trim())}
                        onFocus={showList}
                    />
                    {listShown && (
                        <OperatorList
                            operators={operators}
                            onSelect={onSelect}
                            position={inputPosition}
                            onBlur={() => setListShown(false)}
                            filter={filterName}
                        />
                    )}
                </div>
            )}
        </section>
    );
};

interface ListProps {
    operators: (Operator & { selected: boolean })[];
    filter: string;
    onSelect: (id: number, selected: boolean) => void;
    position: { top: number; left: number; width: number };
    onBlur: () => void;
}

const OperatorList = ({
    operators,
    filter,
    onSelect,
    position,
    onBlur,
}: ListProps) => {
    const [list, setList] = useState(operators);

    useEffect(() => {
        setList(filter ? filterList(filter, operators) : operators);
    }, [filter, operators]);

    const filterList = (
        filter: string,
        list: (Operator & { selected: boolean })[]
    ): (Operator & { selected: boolean })[] => {
        if (!filter) return list;
        const normalizedFilter = normalize(filter);
        return list.filter((operator) =>
            normalize(`${operator.firstName} ${operator.lastName}`).includes(
                normalizedFilter
            )
        );
    };

    return (
        <div
            className={styles.listOverlay}
            data-listoverlay={true}
            onClick={(ev) => {
                const target = (ev.target as HTMLDivElement).dataset[
                    'listoverlay'
                ];
                if (target) return onBlur();
            }}
        >
            <ul
                className={styles.list}
                style={{
                    top: position.top,
                    left: position.left,
                    width: position.width,
                }}
            >
                {list.map((operator, idx) => (
                    <li
                        key={idx}
                        onClick={() =>
                            onSelect(operator.id, !operator.selected)
                        }
                    >
                        <Checkbox id={operator.id} checked={operator.selected}>
                            {operator.firstName} {operator.lastName}
                        </Checkbox>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SelectOperator;
